import React, {useEffect, useRef, useState} from "react";
import {InfoBox, StepContentWrapper} from "../style";
import {useFormContext} from "../../../context/formContext";
import {Step} from "../../SdirWizard";
import {RadioButtonGroup} from "../../RadioButtonGroup/RadioButtonGroup";

const probabilityBeforeOptions = [
    {name: "Svært usannsynlig — inntreffer én gang pr. 50 år eller sjeldnere", value: "1"},
    {name: "Usannsynlig — inntreffer én gang pr. 10 år eller sjeldnere", value: "2"},
    {name: "Mulig — inntreffer årlig eller sjeldnere", value: "3"},
    {name: "Sannsynlig — inntreffer flere ganger årlig", value: "4"},
];

const consequenceBeforeOptions = [
    {name: "Ufarlig — ubetydelig skade på menneske, miljø eller materiell", value: "1"},
    {name: "Farlig — Småskader med kort fravær, moderate miljøskader, mindre lokal skade på fartøy/meteriell", value: "2",}, 
    {name: "Kritisk — Alvorlig personskade med langt fravær eller varige mén, større lokale skader på miljø, alvorlige skader på fartøy/materiell", value: "3",},
    {name: "Katastrofal — Kan resultere i død for én eller flere personer, meget alvorlige skader på miljø, tap av, eller kritisk skade på fartøy/materiell", value: "4",},
];

export const AssessBeforeMeasuresStep = ({title, risk} : Step) => {
    const formContext = useFormContext();

    const [probabilityBefore, setProbabilityBefore] = useState(formContext.getValue("probabilityBefore")?.toString() ?? "1");
    const [consequenceBefore, setConsequenceBefore] = useState(formContext.getValue("consequenceBefore")?.toString() ?? "1");
    const ref = useRef(null);
    
    useEffect(() => {
        formContext.setValue("probabilityBefore", Number(probabilityBefore));
        formContext.setValue("consequenceBefore", Number(consequenceBefore));
    }, [probabilityBefore, consequenceBefore])

    return (
        <StepContentWrapper ref={ref}>
            {risk?.name && <p><strong>Fare:</strong> "{risk?.name}"</p>}
            <h3>{title}</h3>
            <InfoBox>
                Vurder hvor stor sannsynlighet det er for at faren oppstår — og hvilken konsekvens det da
                eventuelt vil ha — dersom <strong>ingen tiltak</strong> er iverkstatt enda.
            </InfoBox>
            <h4>Hvor sannsynlig er det at dette oppstår?</h4>
            <RadioButtonGroup
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProbabilityBefore(e.target.value);
                }}
                name="probabilityBefore"
                options={probabilityBeforeOptions}
                value={probabilityBefore}
            />
            <br></br>
            <h4>Hva blir konsekvensen dersom dette oppstår?</h4>
            <RadioButtonGroup
                options={consequenceBeforeOptions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConsequenceBefore(e.target.value);
                }}
                name="consequenceBefore"
                value={consequenceBefore}
            />
        </StepContentWrapper>
    );
};


