import React, { useEffect, useState } from "react";
import {
  SdiCloseCross,
  SdirButton,
  SdirIconButton,
  SdirInput,
  styles,
} from "@sdir/sds";
import styled from "styled-components";
import { SdirFormModal } from "../SdirModal";
import { WorkProcess, WorkTask } from "types/types";
import { addWorkTasks, addCustomWorkTask } from "../../data/riskManagementApi";
import { ListAccordion } from "ui/ListAccordion";
import { FilterList } from "ui/FilterList";
import { CustomTaskInput } from "./CustomTaskInput";
import Check from "@sdir/sds/lib/types/src/components/Atoms/SdiIcons/Check";
import { CheckBox } from "ui/CheckBox/CheckBox";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => unknown;
  assessmentCategories: any;
  taskChoices?: Array<WorkProcess>;
  vesselID: number;
}

const AddTaskComponent = ({
  isOpen,
  onClose,
  assessmentCategories,
  taskChoices,
  vesselID,
}: ModalProps) => {
  let [customWorkTaskID, setCustomWorkTaskID] = useState(0);
  let [selectedAssessmentCategories, setSelectedAssessmentCategories] =
    useState<number[]>([]);
  let [filteredTaskChoices, setFilteredTaskChoices] =
    useState<WorkProcess[] | undefined>(taskChoices);
  let [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  let [selectedCustomTasks, setSelectedCustomTasks] = useState<number[]>([]);

  const [totalListCount, setTotalListCount] = useState(0);
  const [filteredListCount, setFilteredListCount] = useState(0);

  const [taskInput, setTaskInput] = useState("");
  const [customWorkTask, setCustomWorkTask] = useState<WorkTask>();
  const [customWorkTasks, setCustomWorkTasks] = useState<WorkTask[]>([]);

  const [customWorkTaskInputList, setCustomWorkTaskInputList] = useState(Array<{index: number, workTaskName : string, workProcessId: number, vesselId: number}>); 

  let [currentTaskChoices, setCurrentTaskChoices] =
    useState<WorkProcess[] | undefined>(taskChoices);

  let handleSubmit = async () => {
    await addWorkTasks(selectedTasks, vesselID);
    await addAllCustomTasks(); 
    onClose();
    setSelectedTasks([]);
    setSelectedCustomTasks([]);
    setSelectedAssessmentCategories([]);
  };
  
  const toggleTaskSelected = async (selected: boolean, workTaskID: number) => {
    if (selected) {
      setSelectedTasks([...selectedTasks, workTaskID]);
    } else {
      const selectedTasksCopy = [...selectedTasks];
      selectedTasksCopy.splice(selectedTasksCopy.indexOf(workTaskID), 1);
      setSelectedTasks(selectedTasksCopy);
    }
  };
  
  const toggleCustomTaskSelected = async (selected: boolean, index: number) => {
    if (selected) {
      setSelectedCustomTasks([...selectedCustomTasks, index]);
    } else {
      const selectedCustomTasksCopy = [...selectedCustomTasks];
      selectedCustomTasksCopy.splice(selectedCustomTasksCopy.indexOf(index), 1);
      setSelectedCustomTasks(selectedCustomTasksCopy);
    }
  };

  const filterTaskChoices = () => {
    const choicesFiltered = taskChoices
      ?.map((workProcess) => {
        return {
          ...workProcess,
          workTasks: workProcess.workTasks?.filter(
            (workTask) =>
              !selectedAssessmentCategories[0] ||
              selectedTasks.includes(workTask.id) ||
              selectedAssessmentCategories.find((cat) =>
                workTask.assessmentCategoryIds.includes(cat)
              )
          ),
        };
      })
      .filter((workProcess) => workProcess.workTasks?.length > 0);

    setFilteredListCount(
      choicesFiltered?.flatMap((x) => x.workTasks).length ?? 0
    );
    setFilteredTaskChoices(choicesFiltered);
  };

  useEffect(() => {
    setTotalListCount(taskChoices?.flatMap((x) => x.workTasks).length ?? 0);
    filterTaskChoices();
  }, [taskChoices, isOpen]);

  useEffect(() => {
    filterTaskChoices();
  }, [selectedAssessmentCategories]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedAssessmentCategories([]);
      setSelectedTasks([]);
      setFilteredTaskChoices([]);
      setFilteredListCount(0);
      setTotalListCount(0);
    }
  }, [isOpen]);

    const addAllCustomTasks = async () => {
        const addedWorkTasks : number[] = [];

        await Promise.all(customWorkTaskInputList
            .filter((customWorkTask) => selectedCustomTasks.includes(customWorkTask.index))
            .map(async customWorkTask => {
                await addCustomWorkTask(
                    customWorkTask.workTaskName,
                    customWorkTask.workProcessId,
                    customWorkTask.vesselId
                );

                addedWorkTasks.push(customWorkTask.index);
            }));

        setCustomWorkTaskInputList(customWorkTaskInputList
            .filter((workTaskInput) => !addedWorkTasks.includes(workTaskInput.index)));
    };

  const addCustomTaskInput = async (workProcessId: number, taskName : string) => {
    if (taskName.length > 0) {
      setCustomWorkTaskInputList([...customWorkTaskInputList, {"index":  100000 + customWorkTaskID, "workTaskName": taskName, "workProcessId": workProcessId, "vesselId": vesselID}]); 
      setCustomWorkTaskID(customWorkTaskID + 1);
    }
    setTaskInput("");
  }

  const removeCustomTaskInput = (customWorkTaskID : number) => {
    setCustomWorkTaskInputList((prevList) => prevList.filter((listItem) => listItem.index !== customWorkTaskID));
  }

  return (
    <>
      <SdirFormModal
        visible={isOpen}
        onCancel={onClose}
        title="Legg til arbeidsoppgaver"
        onSubmit={handleSubmit}
        cancelText="Avbryt"
        okText="Legg til"
        width="1200px"
        height="1000px"
      >
        <Top>
          <h3>Velg arbeidsoppgaver</h3>
          <p>
            Her ser du anbefalte arbeidsoppgaver som vi har forhåndsdefinert for
            deg. Velg de som passer til ditt fartøy — eller opprett dine egne.
          </p>
        </Top>
        <ColumnLayout>
          <Sidebar>
            <FilterList
              title="Vis bare arbeidsoppgaver for"
              listItems={assessmentCategories}
              addFilter={(id: number) => {
                setSelectedAssessmentCategories([
                  ...selectedAssessmentCategories,
                  id,
                ]);
              }}
              removeFilter={(id: number) => {
                const selectedIndexesCopy = [...selectedAssessmentCategories];
                selectedIndexesCopy.splice(
                  selectedAssessmentCategories.indexOf(id),
                  1
                );
                setSelectedAssessmentCategories(selectedIndexesCopy);
              }}
            ></FilterList>
          </Sidebar>
          <Main>
            <TaskSummary>
              Viser {filteredListCount} av totalt {totalListCount}{" "}
              arbeidsoppgaver
            </TaskSummary>
            {filteredTaskChoices?.map((taskChoice, index) => (
              <ListAccordion
                key={taskChoice.id}
                title={taskChoice.name}
                open={index === 0}
              >
                <CheckBoxList>
                  <>
                    {taskChoice.workTasks?.map((workTask: WorkTask) => (
                      <CheckBox key={workTask.id} name={workTask.name} id={workTask.id} onChange={toggleTaskSelected} checked={selectedTasks.includes(workTask.id)}/>
                    ))}
                    {customWorkTaskInputList?.map((workTaskInput) => ( 
                      (workTaskInput.workProcessId === taskChoice.id) && (
                   <CheckBox key={workTaskInput.index} name={workTaskInput.workTaskName} id={workTaskInput.index} onChange={toggleCustomTaskSelected} checked={selectedCustomTasks.includes(workTaskInput.index)} onRemove={removeCustomTaskInput} />
                      )
                    ))}
                  </>
                </CheckBoxList>
                <CustomTaskInput taskChoiceID={taskChoice.id} addTask={addCustomTaskInput}></CustomTaskInput>
              </ListAccordion>
            ))}
          </Main>
        </ColumnLayout>
      </SdirFormModal>
    </>
  );
};

const RemoveTaskButton = styled.button`
  display: inline-flex; 
  align-items: center;
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: 1.75rem;
  font-style: inherit;
  font-weight: 600;
  line-height: inherit;
  padding: 0;
  color: ${styles.colors.alert.errorDark}; 
  margin-left: 16px;
  margin-bottom: 2px; 
  padding: 4px 8px; 
  cursor: pointer;

  &:hover, &:focus-visible {
    background-color: ${styles.colors.alert.errorLight};
  }
`;

const CheckBoxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const TaskSummary = styled.p`
  font-size: ${styles.font.size.md};
  color: ${styles.colors.font.text85};
  margin: 0;
`;

const ColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 35% auto;

  @media (max-width: ${styles.screen.medium}) {
    grid-template-columns: 1fr;
  }
`;

const Main = styled.div`
  padding: 4rem 4rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (max-width: ${styles.screen.medium}) {
    padding: 0;
  }
`;

const Top = styled.div`
  padding: 3rem;
  background-color: ${styles.colors.background.light};
  border-bottom: 1px solid ${styles.colors.primary10};
  * {
    margin: 0;
    max-width: 80ch;
  }

  h3 {
    margin-bottom: 0.5rem;
  }
`;

const Sidebar = styled.aside`
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-right: 1px solid ${styles.colors.primary10};
`;

export const AddTaskModal = AddTaskComponent;
