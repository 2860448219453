import { createContext, useContext } from 'react';
import {Risk, WorkTask} from 'types/types';

export interface VesselContextProps {
    currentVesselId: number;
    openRiskModalForWorkTask: (workTask: WorkTask) => void;
    openEditRiskModal: (risk: Risk, workTask: WorkTask) => void;
    currentRisk?: Risk;
    setCurrentRisk?: (risk: Risk) => void;
    openConfirmDeleteModalForWorkTask?: (workTask: WorkTask) => void;
    openConfirmDeleteModalForRisk?: (risk: Risk) => void;
}

const VesselContext = createContext<VesselContextProps>({
    currentVesselId: 0,
    openEditRiskModal: (risk: Risk, workTask: WorkTask) => {},
    openRiskModalForWorkTask: (workTask: WorkTask) => {}
});

export function VesselContextProvider(props: {value: VesselContextProps, children: any}) {
    const {value, children} = props

    return (
        <VesselContext.Provider value={value}>
            {children}
        </VesselContext.Provider>
    )
}

export function useVesselContext() {
    return useContext(VesselContext);
}