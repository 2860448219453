import styled from "styled-components";
import {Loader} from "@sdir/sds";
import React from "react";

export const LoadingModal = () => <LoaderWrapper><Loader></Loader></LoaderWrapper>;

const LoaderWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(255,255,255,0.75);
`;