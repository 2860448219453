import {PrivateRoute, StandardRoutes} from "@sdir/auth/lib/components";
import {Route, Routes} from "react-router";
import {HomePage} from "./pages/HomePage";
import {AssessmentPage} from "./pages/AssessmentPage";
import Providers from "../providers/Providers";
import {AppLayout} from "../layouts";
import {HashRouter} from "react-router-dom";
import {NotFoundPage} from "./pages/NotFoundPage";
import { LandingPage } from "./pages/LandingPage";

export const RiskManagementRoutes = () => {
    return (
        <Providers>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<AppLayout/>}>
                        <Route index element={<LandingPage />} />
                        <Route path="app" element={<PrivateRoute><HomePage/></PrivateRoute>}/>
                        <Route path="app/risikovurdering/:vesselId"
                               element={<PrivateRoute><AssessmentPage/></PrivateRoute>}/>
                        <Route path="*" element={<StandardRoutes notFoundComponent={<NotFoundPage/>}/>}/>
                    </Route>
                </Routes>
            </HashRouter>
        </Providers>
    );
};
