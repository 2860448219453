import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router";
import styled from "styled-components";
import { Icon, SdirHeader, SdirModal, SdirFooter, styles } from "@sdir/sds";
import { GlobalContextProvider } from "../context/globalContext";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuthentication, useCurrentUser } from "@sdir/auth/lib/hooks";
import {MenuItem} from "@sdir/sds/lib/types/src/components/Organisms/SdirHeader/sharedTypes";

const AppLayoutComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);

    const toolMenuItems : MenuItem[] = [
        {
            type: 'button',
            icon: <Icon name={faInfoCircle as any} padding="0" />,
            text: 'Hjelp',
            onClick: () => setHelpModalIsOpen(helpModalIsOpen => !helpModalIsOpen),
        }
    ]

    const navigate = useNavigate();
    const auth = useAuthentication();
    const handleLoginClick = () => navigate('/app');
    const handleLogoutClick = () => auth.logout();
    const currentUser = useCurrentUser();

    return (
        <GlobalContextProvider value={{ isModalOpen, setIsModalOpen }}>
            <AppLayoutWrapper scrollLock={isModalOpen}>
                <React.Suspense fallback={<p>Laster...</p>}>
                    <SdirHeader
                        appName="Risikovurdering"
                        maxContentWidth={styles.screen.xLarge}
                        backLink={{ url: "https://www.sdir.no/", text: "Gå til sdir.no" }}
                        hasLogin={true}
                        toolMenuItems={toolMenuItems}
                        onLogInClick={handleLoginClick}
                        menuToggleText="Meny"
                        isLoggedIn={currentUser && !currentUser?.expired}
                        loggedInAs={currentUser && !currentUser?.expired ? {
                            username: currentUser?.profile?.name || "",
                            identification: currentUser?.profile?.reportee || ""
                        } : undefined}
                        onLogOutClick={handleLogoutClick}
                    />
                    <SdirModal visible={helpModalIsOpen} onCancel={() => setHelpModalIsOpen(!helpModalIsOpen)}>
                        <HelpText>
                            <h2>Om verktøyet</h2>
                            <p>Bruk verktøyet til å kartlegge hvilke arbeidsoppgaver som skal utføres på ditt fartøy og hvilke farer som kan oppstå ved utførelsen av disse. Opprett tiltak for hver fare som skal iverksettes for å få ned risikoen for at faren oppstår.</p>
                            <h2>Begreper</h2>
                            <ul>
                                <li><strong>Kategori</strong> — samling av arbeidsoppgaver som utføres på fartøyet. Du velger selv hvilke kategorier du vil dele inn arbeidsoppgavene dine i</li>
                                <li><strong>Oppgave</strong> — oppgave som utføres ombord på fartøyet</li>
                                <li><strong>Fare</strong> — ved utføring av en arbeidsoppgave kan en fare oppstå, og det er din oppgave å vurdere risikoen av faren</li>
                                <li><strong>Sikkerhetsinstruks</strong> — for hver fare kan du opprette ulike sikkerhetsinstrukser som skal hjelpe å veilede mannskapet/passasjerene. Disse instruksene kan skrives ut og henges opp der hvor arbeidsoppgaven skal utføres</li>
                            </ul>
                        </HelpText>
                    </SdirModal>
                    <Wrapper>
                        <Outlet />
                    </Wrapper>
                    <SdirFooter 
                        contactHeader="Kontakt oss"
                        contactLinks={[
                            { url: 'https://www.sdir.no/om-direktoratet/kontakt-oss/kontakt/', text: 'Kontakt oss', type: 'external' },
                            { url: 'https://www.sdir.no/om-direktoratet/presentasjon-av-direktoratet/', text: 'Mer om Sjøfartsdirektoratet', type: 'external' }
                        ]}
                        socialHeader="Følg oss"
                        socialLinks={[
                            { url: 'https://www.facebook.com/sjofartsdir/', text: 'Facebook', type: 'facebook' },
                            { url: 'https://www.linkedin.com/company/sjofartsdir', text: 'Linkedin', type: 'linkedin' }
                        ]}
                        tagline="- Den foretrukne maritime administrasjonen"
                        maxContentWidth={styles.screen.xLarge}
                    />
                </React.Suspense>
            </AppLayoutWrapper>
        </GlobalContextProvider>
    );
};

const AppLayoutWrapper = styled.div<{ scrollLock: boolean }>`
    max-height: ${props => props.scrollLock ? '100vh' : 'none'};
    overflow-y: ${props => props.scrollLock ? 'hidden' : 'auto'};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Wrapper = styled.div`
    max-width: calc(${styles.screen.xLarge} + 2rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    flex-grow: 1;
`

const HelpText = styled.div`
    & > * {
        margin: 0;
    }

    & > * + * {
        margin-top: 1em;
    }

    & > * + *:where(h1, h2, h3) {
        margin-top: 1.5em;
    }

    & > ul {
        padding-left: 2.5rem;
        & > li + li {
            margin-top: 1em;
        }
    }
`


export const AppLayout = React.memo(AppLayoutComponent);
