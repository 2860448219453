import { styles } from "@sdir/sds";
import { useState } from "react";
import styled from "styled-components";

interface CategoryProps {
  setActiveCategory: React.Dispatch<React.SetStateAction<number | undefined>>;
  categories?: any[];
}

const CategoryMenu = ({ setActiveCategory, categories }: CategoryProps) => {
  let [checkedId, setCheckedId] = useState<number>();

  function selectCategory(id: number | undefined) {
    setCheckedId(id);
    setActiveCategory(id);
  }

  interface CategoryItemProps {
    category?: any;
    key?: string;
  }

  const CategoryMenuItem = ({ category }: CategoryItemProps) => {
    return (
      <li>
        <button
          className={category?.id == checkedId ? "checked" : ""}
          onClick={() => selectCategory(category ? category.id : undefined)}
        >
          <span className="menu-category-title">
            {category?.categoryName ?? "Alle"}
          </span>
          {category?.numberOfItems && (
            <span className="menu-category-number">
              ({category.numberOfItems})
            </span>
          )}
        </button>
      </li>
    );
  };

  return (
    <CategoryMenuWrapper>
      <h2>Kategorier</h2>
      <span className="menu-category-text">
        Velg hvilken kategori du vil vise arbeidsoppgaver for
      </span>
      <ul>
        <CategoryMenuItem category={undefined} key="all" />
        {categories?.map((category) => (
          <CategoryMenuItem
            category={category}
            key={category.id}
          ></CategoryMenuItem>
        ))}
      </ul>
    </CategoryMenuWrapper>
  );
};

const CategoryMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${styles.colors.background.secondary};
  border-radius: ${styles.card.borderRadius};
  box-shadow: ${styles.card.boxShadow};
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 42px;
  h2 {
    font-size: ${styles.font.size.xl};
    line-height: 1.375;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    margin-top: 0;
    gap: 24px;
    margin-inline-start: -16px;
    margin-inline-end: -16px;
  }

  li {
    display: flex;
  }

  button:focus-visible {
    border: none;
    outline: 2px solid ${styles.colors.primary};
    border-radius: 99px;
  }

  button {
    color: ${styles.colors.primary};
    padding: 8px 16px;
    width: 100%;
    margin: 0;
    border: none;
    background-color: initial;
    font-size: initial;
    text-align: initial;
  }

  button:hover {
    background-color: ${styles.colors.primary10};
    border-radius: 99px;
    border: none;
  }

  .checked {
    background-color: ${styles.colors.primary10};
    border-radius: 99px;
    border: none;
  }

  .menu-category {
    &-text {
      border-bottom: 1px solid ${styles.colors.border};
      color: ${styles.colors.font.text50};
      padding-bottom: 10px;
      margin-bottom: 34px;
    }

    &-title {
      margin-right: 4px;
    }
  }
`;

export default CategoryMenu;
