import { Measure, Risk, SafetyInstruction } from "../../types/types";
import styled from "styled-components";
import { SdiDownload, styles } from "@sdir/sds";
import { Matrix } from "ui/Matrix";
import { formatDateStringShort } from "../../helpers/dateHelpers";
import {
    CreateSafetyInstructionPdf,
    markMeasureAsComplete
} from "../../data/riskManagementApi";
import { useVesselContext } from "../../context/vesselContext";
import { useState } from "react";
import { ConfirmDialog } from "../ConfirmDialog";
import { PdfButton } from "../PdfButton/PdfButton";
import { safetyInstructionTypes } from "../../types/SafetyInstructionIcons";

export interface RiskComponentProps {
    risk: Risk;
    allowCompletion: boolean;
}

export const RiskComponent = ({ risk, allowCompletion }: RiskComponentProps) => {
    const vesselContext = useVesselContext();

    const safetyInstructions = risk?.measures
        ?.flatMap((x) => x.safetyInstruction as SafetyInstruction)
        .filter((x) => !!x && (x.headerText || x.selectedIcons?.length > 0));

    return (
        <>
            {risk?.measures && risk.measures.length > 0 &&
                <MeasuresContainer>
                    <SummaryHeading>Tiltak</SummaryHeading>
                    {risk.measures.map((measure: Measure, index) => (
                        <MeasureComponent measure={measure} key={index} allowCompletion={allowCompletion} />
                    ))}
                </MeasuresContainer>
            }
            {safetyInstructions && safetyInstructions.length > 0 &&
                <MeasuresContainer>
                    <SummaryHeading>Sikkerhetsinstrukser</SummaryHeading>
                    {safetyInstructions.map((safetyInstruction: SafetyInstruction, index) => (
                        <MeasureContainer key={"measure" + safetyInstruction.headerText + index}>
                            <MeasureContainerList>
                                <SafetyInstructionItem>
                                    <SafetyInstructionIconsGrid>
                                        {safetyInstruction
                                            .selectedIcons
                                            .map((iconType, index) => {
                                                return {
                                                    key: index,
                                                    src: safetyInstructionTypes.find(s => s.type === iconType)?.thumbnail ?? ""
                                                }
                                            })
                                            .filter(icon => icon.src)
                                            .map((icon) => (
                                                <SafetyInstructionThumbnail
                                                    key={icon.key}
                                                    src={icon.src} />
                                            ))}
                                    </SafetyInstructionIconsGrid>
                                    <SafetyInstructionText>
                                        <strong>Instruks:</strong>&nbsp;{safetyInstruction.headerText}
                                    </SafetyInstructionText>
                                    {allowCompletion && <PdfButton onClick={async (e) => {
                                        e.preventDefault();
                                        const pdf = await CreateSafetyInstructionPdf(safetyInstruction.id, vesselContext.currentVesselId);
                                        var file = window.URL.createObjectURL(pdf);
                                        window.open(file, "_blank");
                                    }}>
                                        Last ned
                                        <SdiDownload height={"18px"} style={{ marginLeft: "8px" }} />
                                    </PdfButton>}
                                </SafetyInstructionItem>
                            </MeasureContainerList>
                        </MeasureContainer>
                    ))}
                </MeasuresContainer>
            }
            {
                risk && risk.consequenceBefore
                && risk.consequenceAfter
                && risk.probabilityBefore
                && risk.probabilityAfter
                && (
                    <>
                        <SummaryHeading>Utregnet risiko</SummaryHeading>
                        <Matrix beforeConsequence={risk.consequenceBefore}
                            afterConsequence={risk.consequenceAfter}
                            beforeProbability={risk.probabilityBefore}
                            afterProbability={risk.probabilityAfter}></Matrix>
                    </>)
            }
        </>
    )
}

const MeasureComponent = ({
    measure,
    allowCompletion,
    children
}: { measure: Measure, allowCompletion: boolean, children?: React.ReactNode }) => {
    const [completed, setCompleted] = useState(measure.completed);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const vesselContext = useVesselContext();

    const expired = Date.now() > (measure.dueDate ? new Date(measure.dueDate) : Date.now());

    return (
        <MeasureContainer key={"measure" + measure.title}>
            <MeasureContainerList>
                <li>
                    <strong>Tiltak:</strong> {measure.description}
                </li>
                <li>
                    <strong>Ansvarlig:</strong> {measure.responsible} — {measure.responsibleEmail}
                </li>
                <li>
                    <DateText
                        strike={expired}><strong>Frist:</strong> {formatDateStringShort(measure.dueDate, "Ingen frist")}
                        {allowCompletion && !completed &&
                            <CompleteButton onClick={() => setShowConfirmModal(true)}>Merk som utført</CompleteButton>}
                    </DateText>
                    {expired && <DueDateExpired>Utgått</DueDateExpired>}
                    {allowCompletion && completed && <CompletedText>Utført</CompletedText>}
                </li>
            </MeasureContainerList>
            {showConfirmModal &&
                <ConfirmDialog visible={showConfirmModal} onConfirm={async () => {
                    if (!measure?.id) return;

                    await markMeasureAsComplete(measure.id, vesselContext.currentVesselId);
                    setCompleted(true);
                    setShowConfirmModal(false);
                }}
                    onCancel={() => setShowConfirmModal(false)}
                    title={"Bekreft at tiltak er utført"}
                    text={`Er du sikker på at du vil markere tiltaket: "${measure.description}" som fullført? Dette kan ikke endres senere`}
                    confirmText={"Tiltaket er utført"} cancelText={"Avbryt"} />
            }
            {children}
        </MeasureContainer>
    )
};


const MeasuresContainer = styled.div`
  margin-bottom: 32px;
`;
const MeasureContainer = styled.div`
  padding: 2rem;
  border: 1px solid ${styles.colors.primary9};
  margin-bottom: -1px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
const CompleteButton = styled.button`
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  cursor: pointer;
  color: #003C69;
  text-decoration: underline;
  margin-left: 8px;
`;
const CompletedText = styled.span`
  margin-left: 8px;
  color: #53A35D;
`;
const DueDateExpired = styled.span`
  margin-left: 8px;
  color: #BF615C;
`;
const DateText = styled.div<{ strike: boolean }>`
  text-decoration: ${props => props.strike ? "line-through !important" : "none"};
  display: inline-block;
`;
const MeasureContainerList = styled.ul`
  flex: 1;
`;
const SafetyInstructionItem = styled.li`
  display: flex;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
const SafetyInstructionText = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
`;
const SafetyInstructionThumbnail = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  object-position: center;
  padding: 4px;
  border: 1px solid ${styles.colors.primary9};
`;
const SafetyInstructionIconsGrid = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const SummaryHeading = styled.p`
    margin-bottom: 4px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.75rem;
    color: ${styles.colors.font.text50};
`