import { styles } from '@sdir/sds';
import React, { useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

export interface TextareaProps {
    name?: string;
    id?: string;
    className?: string;
    cols?: number;
    rows?: number;
    disabled?: boolean;
    tabIndex?: number;
    maxlength?: number;
    placeholder?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    contentValue?: string;
    error?: string;
    required?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
                                               name,
                                               id = 'textarea',
                                               className,
                                               cols = 46,
                                               rows = 3,
                                               disabled,
                                               tabIndex,
                                               maxlength,
                                               placeholder,
                                               onChange,
                                               onBlur,
                                               contentValue,
                                               error,
                                               required
                                           }) => {
    const [containsValue, setContainsValue] = useState(false);

    const [textareaValue, setTextareaValue] = useState(contentValue);
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaValue(e.target.value);
        onChange && onChange(e);
    };

    useEffect(() => {
        setTextareaValue(contentValue);
    }, [contentValue]);

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            setContainsValue(!!e.target.value);
            onBlur?.(e);
        },
        [onBlur]
    );

    return (
        <TextareaContainer
            className={className}
            containsValue={containsValue}
            hasError={Boolean(error)}
        >
            <Label htmlFor={id} active={containsValue}>
                {placeholder}
            </Label>
            <textarea
                id={id}
                onBlur={handleBlur}
                name={name}
                cols={cols}
                rows={rows}
                disabled={disabled}
                tabIndex={tabIndex}
                maxLength={maxlength}
                placeholder={placeholder}
                onChange={handleChange}
                value={textareaValue}
                required={required}
            />
            {error && <ErrorLabel>{error}</ErrorLabel>}
        </TextareaContainer>
    );
};

const Label = styled.label<{ active: boolean }>`
  font-size: 14px;
  color: ${styles.colors.font.primary.blue};
  margin-left: 12px;
  position: relative;
  font-family: ${styles.fonts.stfMedium};
  background: linear-gradient(0deg, white 48%, rgba(0, 0, 0, 0) 48%);
  padding: 0 8px 0 8px;
  transition: all 0.2s ease-in;
  position: absolute;
  top: -10px;
  ${({ active }) =>
    active
        ? css`
          opacity: 1;
          background: linear-gradient(0deg, white 70%, rgba(0, 0, 0, 0) 70%);
          transition: all 0.2s ease-in;
        `
        : css`
          opacity: 0;
        `}
`;

const ErrorLabel = styled.label`
  position: relative;
  margin-left: 2rem;
  top: -2rem;
`;

const TextareaContainer = styled.div<{ containsValue: boolean; hasError: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  textarea {
    box-sizing: border-box;
    margin: 0 0 1em;
    border: 1px solid ${styles.colors.primary8};
    font-family: ${styles.fonts.stfBook};
    color: ${styles.colors.font.text85};
    border-radius: 4px;
    background: #fff;
    resize: both;
    outline: none;
    padding: 16px;
    font-size: 2rem;
  }

  textarea:focus-visible {
    border: 2px solid ${styles.colors.primary};
    box-shadow: 0 0 3px ${styles.colors.primaryLightBlue1};
    outline-width: 0px;
    color: ${styles.colors.font};
  }

  textarea:focus:not(:placeholder-shown) {
    font-family: ${styles.fonts.stfMedium};
  }

  textarea:hover {
    border-color: ${styles.colors.primary8};
    box-shadow: 0 0 3px ${styles.colors.primaryLightBlue1};
  }

  textarea:disabled {
    color: ${styles.colors.font.text50};
    background: ${styles.colors.background.disabled};
    font-family: ${styles.fonts.stfBook};
    border: 1px solid ${styles.colors.font.text25};
  }

  :focus-within {
    textarea {
      border: 2px solid ${styles.colors.primary};
      box-shadow: 0 0 3px ${styles.colors.primaryLightBlue1};
      outline-width: 0px;
    }
    ${Label} {
      opacity: 1;
      background: linear-gradient(0deg, white 70%, rgba(0, 0, 0, 0) 70%);
      transition: all 0.2s ease-in;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      textarea {
        background-color: ${styles.colors.alert.errorLight};
        border: 2px solid ${styles.colors.alert.errorDark} !important;
        box-shadow: none !important;
      }

      label {
        color: ${styles.colors.alert.errorDark};
        font-family: ${styles.fonts.stfMedium};
        font-size: 14px;
      }

      label:first-child {
        background: linear-gradient(
          0deg,
          ${styles.colors.alert.errorLight} 60%,
          transparent 48%
        ) !important;
      }
    `}

  ${({ containsValue }) =>
    containsValue &&
    css`
      textarea {
        font-family: ${styles.fonts.stfMedium} !important;
      }
    `}
`;

export default Textarea;