import {FormContextProvider, useFormContext} from "../../context/formContext";
import {DescribeRiskStep} from "./Steps/DescribeRiskStep";
import {AssessBeforeMeasuresStep} from "./Steps/AssessBeforeMeasuresStep";
import {AddMeasuresStep} from "./Steps/AddMeasuresStep";
import {AssessAfterMeasuresStep} from "./Steps/AssessAfterMeasuresStep";
import {SummaryStep} from "./Steps/SummaryStep";
import {Risk, WorkTask} from "../../types/types";
import {useEffect, useState} from "react";
import {SdirWizard, Step} from "../SdirWizard";

export interface EditRiskWizardProps {
    isOpen: boolean;
    onClose: () => unknown;
    editRisk: (risk: Risk) => void;
    risk?: Risk;
    workTask?: WorkTask;
    setRisk: (risk: Risk | undefined) => void;
    currentStep: number;
    setCurrentStep: (to: number) => void;
}

const EditRiskWizardComponent = ({
                                     isOpen,
                                     workTask,
                                     onClose,
                                     editRisk,
                                     risk,
                                     setRisk,
                                     currentStep,
                                     setCurrentStep
                                 }: EditRiskWizardProps) => {
    const [formValues, setFormValues] = useState(new Map(Object.entries(risk ?? {})));

    useEffect(() => {
        setFormValues(new Map(Object.entries(risk ?? {})));
    }, [isOpen]);

    return (
        <FormContextProvider getValue={(key: string) => formValues.get(key)}
                             setValue={(key: string, value: any) => {
                                 const newFormValues = formValues;
                                 newFormValues.set(key, value);
                                 
                                 setRisk(Object.fromEntries(newFormValues) as Risk);
                                 setFormValues(newFormValues);
                             }}>
            <SdirWizard
                visible={isOpen}
                category={''}
                title={`Rediger fare for "${workTask?.name}"`}
                preferredWidth={"1200px"}
                preferredHeight={"800px"}
                onCancel={onClose}
                onOK={async () => {
                    const risk = Object.fromEntries(formValues) as Risk;

                    await editRisk(risk);
                    setRisk(undefined);
                }}
                okText={"Oppdater"}
                cancelText={"Avbryt"}
                nextText={"Neste"}
                prevText={"Forrige"}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            >
                <DescribeRiskStep title={"Registrer fare"} />
                <AssessBeforeMeasuresStep title={"Vurder fare før tiltak"} risk={risk} />
                <AddMeasuresStep title={"Registrer tiltak"} risk={risk} />
                <AssessAfterMeasuresStep title={"Evaluer fare etter tiltak"} risk={risk} />
                <SummaryStep title={"Sammendrag"} risk={risk}/>
            </SdirWizard>
        </FormContextProvider>
    );
};

export const EditRiskWizard = EditRiskWizardComponent;
