import { SdirButton } from "@sdir/sds";
import { useState } from "react";
import styled from "styled-components";

export interface CustomTaskInputProps {
  taskChoiceID: number;
  addTask: (...args: any[]) => Promise<void>;
}

const CustomTaskInputComponent = ({
  taskChoiceID,
  addTask,
}: CustomTaskInputProps) => {
  let [taskInput, setTaskInput] = useState("");

  return (
    <CustomTaskInputWrapper>
      <input
          type={"text"}
        name="workTask"
        placeholder="Navn på ny arbeidsoppgave"
        value={taskInput}
        id={taskChoiceID.toString()}
        onChange={(e) => {
            e.preventDefault();
            setTaskInput(e.target.value);
        }}
      />
      <label className="task-input-label" htmlFor="worktask">
        Navn på ny arbeidsoppgave
      </label>
      <SdirButton 
          htmlType={"button"}
        text="Opprett"
        onClick={(e) => {
          e.preventDefault();
          addTask(taskChoiceID, taskInput);
          setTaskInput("");
        }}
      ></SdirButton>
    </CustomTaskInputWrapper>
  );
};

const CustomTaskInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;

  input {
    flex: 0 1 334px;
    height: 48px;
    background-color: #ffffff;
    border: solid 1px #668aa5;
    border-radius: 4px;
    padding: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: black;
    box-sizing: border-box;
    width: 334px;
  }

  input::placeholder {
    font-size: 2rem;
  }

  .task-input-label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const CustomTaskInput = CustomTaskInputComponent;
