import { styles } from "@sdir/sds";
import styled from "styled-components";

export interface StepperStepProps {
  title: string;
  handleStepClick: any;
  isDisabled?: boolean;
}

const StepperStepComponent = ({ title, handleStepClick, isDisabled }: StepperStepProps) => {
  return (
    <StepButton onClick={handleStepClick} disabled={isDisabled} title={title}>
      <span>{title}</span>
    </StepButton>
  );
};

export const StepperStep = StepperStepComponent;

const StepButton = styled.button`
  position: relative;
  border: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  text-align: left;

  &:before {
    counter-increment: list;
    content: counter(list);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: ${styles.colors.primary10};
    line-height: 1;
    border: 2px solid transparent;
    color: ${styles.colors.primary};
    font-family: monospace;
  }

  .active & {
    color: ${styles.colors.primary};
    &:before {
      background-color: ${styles.colors.background.secondary};
      border-color: ${styles.colors.primary};
    }
  }

  .done & {
    color: ${styles.colors.primary};
    &:before {
      background-color: ${styles.colors.primary};
      color: ${styles.colors.font.white};
      content: "✓";
    }
  }

  .disabled & {
    cursor: not-allowed;
  }

  li:last-child & {
    &:before {
      content: "✓";
    }
  }

  span {
    word-break: break-word;
    @media (max-width: 800px) {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
`;
