import styled from "styled-components";
import { styles } from "@sdir/sds";

export const MatrixWrapper = styled.div`
  margin-top: .5rem;
  padding: 2rem 1rem;
  border: 1px solid ${styles.colors.border};
  background-color: ${styles.colors.background.secondary};
  @media (min-width: ${styles.screen.medium}) {
    padding: 3rem 2rem;
  }

  .matrix-grid {
    display: grid;
    grid-template-columns: 1.5em auto;
    grid-template-rows: auto 1.5em;
    gap: 0.5rem;
    margin-bottom: 2rem;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid currentColor;
        top: 0;
        position: absolute;
      }

      span {
        transform: rotate(-90deg) translateX(50%);
        display: block;
        line-height: 0;

        &:after {
          content: "";
          display: block;
          position: absolute;
          height: 1px;
          width: 999em;
          left: calc(100% + 20px);
          background-color: black;
        }
      }
    }

    & > div:last-child {
      display: flex;
      grid-column: 2 / -1;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      align-items: center;

      &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid currentColor;
        right: 0;
        position: absolute;
      }

      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 999em;
        margin-left: 20px;
        background-color: black;
      }

      span {
        display: block;
        line-height: 0;
      }
    }
  }

  .matrix-grid-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2px;
  }

  .matrix-grid-inner-cell {
    font-size: 0.875em;
    aspect-ratio: 2 / 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${styles.screen.medium}) {
      font-size: 1.125em;
    }

    &.low {
      background-color: ${styles.colors.alert.successMedium};
    }

    &.medium {
      background-color: ${styles.colors.alert.warningMedium};
    }

    &.high {
      background-color: ${styles.colors.alert.errorMedium};
    }

    &.selected-solid,
    &.selected-dashed, &.selected-unchanged {
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 90%;
        height: 3.5em;
        border-radius: 100%;
        border: 3px solid ${styles.colors.alert.errorDark};
      }
    }

    &.selected-dashed {
      &:before {
        border-style: dashed;
      }
    }

    &.selected-unchanged {
      &:before {
        border-style: double;
      }
    }
  }

  .matrix-legend-labels {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 1.5rem;
    margin-bottom: 2rem;
    justify-content: center;
  }

  .matrix-legend-label {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1;

    &:before {
      content: "";
      display: block;
      border-radius: 50%;
      border-width: 3px;
      border-color: ${styles.colors.alert.errorDark};
      width: 1.5em;
      height: 1.5em;
    }

    &.dashed:before {
      border-style: dashed;
    }

    &.solid:before {
      border-style: solid;
    }
  }
`;
