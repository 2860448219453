import styled from "styled-components";
import {styles} from "@sdir/sds";
import React from "react";

interface RadioButtonOption {
    name: string;
    value: string;
}

interface RadioButtonGroupProps {
    name: string;
    options: RadioButtonOption[];
    onChange: any;
    value: string;
}

export const RadioButtonGroup = ({options, name, onChange, value}: RadioButtonGroupProps) => {
    return (
        <RadioButtons>
            {
                options.map((option, index) => (
                    <RadioButton key={index}>
                        <input name={name} type="radio" value={option.value} id={`${name}_option_${index}`}
                               onChange={onChange} checked={value===option.value} />
                        <label htmlFor={`${name}_option_${index}`}>{option.name}</label>
                    </RadioButton>
                ))
            }
        </RadioButtons>
    );
}

const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const RadioButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5em;

    input[type="radio"] {
        appearance: none;
        font: inherit;
        color: currentColor;
        width: 30px;
        height: 30px;
        border: 2px solid ${styles.colors.primary};
        border-radius: 50%;
        margin: 0;
        display: grid;
        place-content: center;
        cursor: pointer;

        &::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: ${styles.colors.primary};
            border-radius: 50%;
            transform: scale(0);
        }
        &:checked::before {
            transform: scale(1);
        }

        &:hover {
            outline: none;
            box-shadow: ${styles.colors.primary} 0px 0px 3px;
        }
        
        &:focus-visible {
            transition: 0s;
            outline: 4px solid #1A9AC5 !important;
            outline-offset: 2px !important;
        }
    }
    label {
        font-size: 2.1rem;
        flex: 1;
        color: black;
        cursor: pointer;
    }
`;