import styled from "styled-components";
import { styles } from "@sdir/sds";

export const VesselWrapper = styled.article`
  padding: 3rem 4rem;
  background-color: ${styles.colors.background.secondary};
  box-shadow: ${styles.card.boxShadow};
  margin-bottom: 4rem;
  border-radius: ${styles.card.borderRadius};

  .vessel-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media (min-width: 1000px) {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 3rem;
    }
  }

  .vessel-header-title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    small {
      font-size: ${styles.font.size.md};
    }
    h2 {
      font-size: ${styles.font.size.xl};
      margin: 0;
    }
  }

  .vessel-header-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .vessel-info {
    display: none;
    margin-top: 3rem;
    border-top: 1px solid ${styles.colors.border};
    padding-top: 3rem;
    padding-bottom: 2rem;
    
    &.is-open {
      display: block;
    }
    
    dl {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem 6rem;
    }

    a {
      color: ${styles.colors.font.primary.blue1};
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 2px;
      &:hover {
        text-decoration-thickness: 3px;
      }
    }
  }

  .vessel-expand {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
