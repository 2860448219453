import styled from "styled-components";
import { styles } from "@sdir/sds";
import { Step } from "ui/SdirWizard/SdirWizard";
import { StepperStep } from "./StepperStep";

export interface StepperProps {
  currentStep: number;
  steps?: Step[];
  setCurrentStep: Function;
}

const StepperComponent = ({
  currentStep,
  steps,
  setCurrentStep,
}: StepperProps) => {
  const items = steps?.map((step, index) => {
    let status = "disabled";

    switch (true) {
      case currentStep === index:
        status = "active";
        break;
      case index < currentStep:
        status = "done";
        break;
      case currentStep !== index:
        status = "disabled";
        break;
      case index === steps.length:
        status = "done";
        break;
    }

    return (
      <ListItem key={index} className={status}>
        <StepperStep
          title={step?.title}
          handleStepClick={() => setCurrentStep(index)}
          isDisabled={currentStep < index}
        />
      </ListItem>
    );
  });

  return <List>{items}</List>;
};

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: list;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  @media (min-width: ${styles.screen.medium}) {
    &:before {
      content: "";
      width: 2px;
      background-color: ${styles.colors.primary8};
      top: 0;
      left: 17px;
      bottom: 0;
      display: block;
      position: absolute;
    }
  }

  @media (max-width: ${styles.screen.medium}) {
    flex-direction: row;
  }
`;

const ListItem = styled.li``;

export const Stepper = StepperComponent;
