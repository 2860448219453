import { HttpClient } from "@sdir/httpclient/lib/HttpClient";

const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_EXTERNALAUTHURL;
const baseUrl = process.env.REACT_APP_API_URL;

const httpClient = new HttpClient(baseUrl);

httpClient.addAPIMSubscriptionKeyHeader(process.env.REACT_APP_SUBSCRIPTIONKEY);
httpClient.addOICDAuthorizationHeader(authority!, clientId!);

export { httpClient, baseUrl };
