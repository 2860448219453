import { createContext, useContext } from 'react';

export interface GlobalContextProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

const GlobalContext = createContext<GlobalContextProps>({
    isModalOpen: false,
    setIsModalOpen: () => {}
});

export function GlobalContextProvider(props: {value: GlobalContextProps, children: any}) {
    const {value, children} = props

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}

export function useGlobalContext() {
    return useContext(GlobalContext);
}