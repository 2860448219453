import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ExpandToggleButton, SdirButton, SdirIconButton, SdiTrash, styles } from "@sdir/sds";
import { VesselWrapper } from "./style";
import { Vessel } from "../../types/types";
import styled from "styled-components";
export interface VesselFunctions extends Vessel {
  onClickDelete?: (vessel?: Vessel) => void;
  onClickEdit: (id?: number) => void;
}

export const VesselComponent = (vessel: VesselFunctions) => {
  const [IsOpen, setIsOpen] = useState(false);
  return (
    <VesselWrapper>
      <div className="vessel-header">
        <div className="vessel-header-title">
          <small>Fartøy</small>
          <h2>
            {vessel.callSign} - {vessel.name}
          </h2>
        </div>
        <div className="vessel-header-actions">
          {vessel.onClickDelete && (
            <SdirIconButton
              onClick={() => vessel.onClickDelete!(vessel as Vessel)}
              icon={SdiTrash}
            ></SdirIconButton>
          )}
          <SdirButton type="ghost" onClick={() => vessel.onClickEdit(vessel.id)}>
            Endre fartøysopplysninger
          </SdirButton>
          <SdirButtonLink to={`risikovurdering/${vessel.id}`}>
            Gå til risikovurdering
          </SdirButtonLink>
          <div className="vessel-expand">
            <ExpandToggleButton isToggled={IsOpen} text="" onClick={() => setIsOpen(!IsOpen)} />
          </div>
        </div>
      </div>
      <div className={`vessel-info ${IsOpen ? "is-open" : ""}`}>
        <dl>
          <div>
            <dt>Kontaktperson</dt>
            <dd>
              {vessel.contactName}
              <br />
              <a
                href={`mailto:${vessel.contactEmail}`}
                title="Send e-post"
                aria-label="Send e-post"
              >
                {vessel.contactEmail}
              </a>
              <br />
              {vessel.contactPhone && (
                <a
                  href={`tel:${vessel.contactPhone}`}
                  title="Ring telefonnummer"
                  aria-label="Ring telefonnummer"
                >
                  {vessel.contactPhone}
                </a>
              )}
            </dd>
          </div>
          <div>
            <dt>Byggeår</dt>
            <dd>{vessel?.buildYear ? vessel?.buildYear : "—"}</dd>
          </div>
          <div>
            <dt>Bruttotonnasje</dt>
            <dd>{vessel?.weight ? vessel?.weight : "—"}</dd>
          </div>
          <div>
            <dt>Lengde</dt>
            <dd>{vessel?.length ? vessel?.length : "—"}</dd>
          </div>
          <div>
            <dt>Bredde</dt>
            <dd>{vessel?.width ? vessel?.width : "—"}</dd>
          </div>
          <div>
            <dt>Dybde</dt>
            <dd>{vessel?.depth ? vessel?.depth : "—"}</dd>
          </div>
          <div>
            <dt>Antall personer ombord</dt>
            <dd>{vessel?.numPersonnel ? vessel?.numPersonnel : "—"}</dd>
          </div>
        </dl>
      </div>
    </VesselWrapper>
  );
};

const SdirButtonLink = styled(Link)`
  display: inline-flex;
  border-radius: 4px;
  height: 48px;
  border-color: transparent;
  font-family: ${styles.fonts.stfMedium};
  font-size: 2rem;
  cursor: pointer;
  padding: 0 5rem;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: none;
  color: ${styles.colors.font.white};
  background-color: ${styles.colors.primary};
  color: ${styles.colors.background.secondary};
  align-items: center;

  &:hover {
    background-color: ${styles.colors.background.darkBlue};
  }
`;
