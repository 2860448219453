import styled from "styled-components";
import React, { FormEventHandler, ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { styles, SdirButton, SdiClose } from "@sdir/sds";
import {useFocusLock} from "../../hooks/useFocusLock";

interface ModalProps {
  visible: boolean;
  title?: string;
  children?: ReactNode;
  width?: string;
  height?: string;
  onSubmit?: FormEventHandler;
  onCancel?: VoidFunction;
  onKeyDown?: FormEventHandler;
  okText?: string;
  cancelText?: string;
  isFooter?: boolean;
  className?: string;
  closeButton?: boolean;
  isLoading?: boolean;
  okButtonType?: "default" | "danger" | "primary" | "ghost";
}

export const SdirFormModal: React.FC<ModalProps> = ({
  title,
  visible,
  width,
  height,
  onCancel,
  onSubmit,
  onKeyDown,
  okText,
  cancelText,
  children,
  isFooter = true,
  className,
  closeButton = true,
  okButtonType = "primary",
  isLoading = false
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  useFocusLock(formRef?.current ?? undefined, visible ? 0 : 1);

  const modal = (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(e);
      }}
      ref={formRef}
    >
      <Backdrop />
      <Wrapper className={className}>
        <StyledModal>
          <CustomHeader>
            {title && <h2>{title}</h2>}
            {closeButton && (
              <CloseButton onClick={onCancel}>
                <SdiClose color={styles.colors.primary} />
              </CloseButton>
            )}
          </CustomHeader>
          <Content width={width} height={height}>
            {children}
          </Content>
          {isFooter && (
            <CustomFooter>
              {cancelText && (
                <SdirButton
                  key={cancelText}
                  type="default"
                  onClick={() => {
                    onCancel?.();
                  }}
                  text={cancelText}
                  disabled={isLoading}
                />
              )}
              {okText && (
                <SdirButton
                  htmlType="submit"
                  key={okText}
                  type={okButtonType}
                  text={okText}
                  disabled={isLoading}
                />
              )}
            </CustomFooter>
          )}
        </StyledModal>
      </Wrapper>
    </form>
  );
  return visible ? ReactDOM.createPortal(modal, document.body) : null;
};

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  outline: 0;
  font-family: ${styles.fonts.stfBook};

  /* Below are "global" styles that doesn't get applied to Modal because of React portal */

  div,
  span,
  p,
  a,
  section,
  input,
  article,
  li {
    text-decoration: none;
    line-height: 1.5;
  }
  h1 {
    margin-block-start: 0;
    margin-top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  * {
    box-sizing: border-box;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  background: #00417650;
`;

const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  border-radius: 8px;
  max-height: 95svh;
  border: 2px solid ${styles.colors.background.light};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const CloseButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  :hover {
    cursor: pointer;
  }
`;
const Content = styled.div<{ height?: string; width?: string }>`
  width: 95vw;
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: 10rem;
  overflow: auto;
  scrollbar-gutter: both-edges;
`;

const CustomHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  border-bottom: 1px solid ${styles.colors.primary10};
  gap: 2rem;
  h2 {
    margin: 0;
  }

  button {
    margin-left: auto;
  }
`;
const CustomFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 2rem 3rem;
  border-top: 1px solid ${styles.colors.primary10};

  @media (max-width: ${styles.screen.medium}) {
    gap: 0;
    justify-content: space-between;
  }
`;
