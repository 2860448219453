import {SdiClose, SdirButton, styles} from "@sdir/sds";
import {createRef, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {Stepper} from "ui/Stepper/Stepper";
import {useFocusLock} from "../../hooks/useFocusLock";
import {Risk} from "../../types/types";

export interface Step {
    isSummary?: boolean;
    title: string;
    risk?: Risk;
}

export interface SdirWizardProps {
    visible: boolean;
    title: string;
    category?: string;
    preferredWidth: string;
    preferredHeight: string;
    closeButton?: boolean;
    onCancel: VoidFunction;
    onOK: VoidFunction;
    okText: string;
    cancelText: string;
    nextText: string;
    prevText: string;
    children: JSX.Element[];
    currentStep: number;
    setCurrentStep: (to: number) => void;
}

export const SdirWizard = ({
                               title,
                               category,
                               visible,
                               preferredWidth,
                               preferredHeight,
                               onCancel,
                               onOK,
                               okText,
                               cancelText,
                               closeButton = false,
                               nextText,
                               prevText,
                               currentStep,
                               setCurrentStep,
                               children,
                           }: SdirWizardProps) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const formRefs = children.map((_) => createRef<HTMLFormElement>());
    let wizardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!visible) {
            return;
        }
    }, [visible, currentStep]);

    useFocusLock(wizardRef?.current ?? undefined, currentStep);

    const stepComponents = children.map((step, index) => {
        return (
            <form
                key={`addriskwizarstep_${index}`}
                style={{display: index === currentStep ? "block" : "none"}}
                ref={formRefs[index]}
                className={index === currentStep ? "active" : "not-active"}
                onSubmit={(e) => e.preventDefault()}
            >
                <>
                    {!step.props.isSummary && step}
                    {step.props.isSummary && index === currentStep && step}
                </>
            </form>
        );
    });

    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    };

    const goToNextStep = () => {
        const form = formRefs[currentStep];

        if (!form.current || !form?.current?.reportValidity()) {
            return;
        }

        setCurrentStep(currentStep + 1);
        scrollToTop();
    };

    const goToPrevStep = () => {
        setCurrentStep(currentStep - 1);
        scrollToTop();
    };

    const wizard = (
        <>
            <Backdrop></Backdrop>
            <Wrapper ref={wizardRef}>
                <StyledModal>
                    <CustomHeader>
                        {category && <span style={{color: styles.colors.secondary.grey1}}>{category}</span>}
                        <h2>{title}</h2>
                        {closeButton && (
                            <CloseButton onClick={(e) => { 
                                e.preventDefault();
                                onCancel();
                            }}>
                                <SdiClose color={styles.colors.primary}/>
                            </CloseButton>
                        )}
                    </CustomHeader>
                    <Content
                        preferredWidth={preferredWidth}
                        preferredHeight={preferredHeight}
                        ref={scrollContainerRef}
                    >
                        <ColumnLayout>
                            <Sidebar>
                                <Stepper
                                    setCurrentStep={setCurrentStep}
                                    steps={children.map((x) => x.props as Step)}
                                    currentStep={currentStep}
                                />
                            </Sidebar>
                            <Main>{stepComponents}</Main>
                        </ColumnLayout>
                    </Content>
                    <CustomFooter>
                        <SdirButton
                            htmlType={"button"}
                            key={cancelText}
                            type="default"
                            onClick={() => {
                                onCancel?.();
                            }}
                            text={cancelText}
                        />
                        <ButtonGroup>
                            {currentStep !== 0 && (
                                <SdirButton
                                    htmlType="button"
                                    key={prevText}
                                    onClick={goToPrevStep}
                                    text={prevText}
                                    style={{display: currentStep === 0 ? "none" : "block"}}
                                />
                            )}
                            {currentStep !== children.length - 1 && (
                                <SdirButton
                                    htmlType="button"
                                    key={nextText}
                                    onClick={goToNextStep}
                                    text={nextText}
                                />
                            )}
                            {currentStep === children.length - 1 && (
                                <SdirButton
                                    htmlType="button"
                                    key={okText}
                                    type="primary"
                                    onClick={(e) => {
                                        onOK?.();
                                    }}
                                    text={okText}
                                />
                            )}
                        </ButtonGroup>
                    </CustomFooter>
                </StyledModal>
            </Wrapper>
        </>
    );
    return visible ? ReactDOM.createPortal(wizard, document.body) : null;
};

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  outline: 0;
  font-family: ${styles.fonts.stfBook};

  /* Below are "global" styles that doesn't get applied to Modal because of React portal */

  div,
  span,
  p,
  a,
  section,
  input,
  article,
  li {
    text-decoration: none;
    line-height: 1.5;
  }

  h1 {
    margin-block-start: 0;
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  * {
    box-sizing: border-box;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  background: #00417650;
`;

const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 8px;
  max-height: 95vh;
  border: 2px solid ${styles.colors.background.light};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const CloseButton = styled.button`
  padding: 0;
  border: none;
  background: none;

  :hover {
    cursor: pointer;
  }
`;
const Content = styled.div<{
    preferredHeight?: string;
    preferredWidth?: string;
}>`
  width: 95vw;
  max-width: ${({preferredWidth}) => preferredWidth};
  height: ${({preferredHeight}) => preferredHeight};
  min-height: 10rem;
  overflow: auto;
`;

const CustomHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 3rem;
  border-bottom: 1px solid ${styles.colors.primary10};
  gap: 1rem;

  h2 {
    margin: 0;
  }

  button {
    margin-left: auto;
  }
`;
const CustomFooter = styled.div`
  display: flex;
  place-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 3rem;
  border-top: 1px solid ${styles.colors.primary10};

  @media (max-width: ${styles.screen.medium}) {
    flex-direction: column;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: ${styles.screen.medium}) {
    justify-content: space-between;
    width: 100%;
  }
`;

const ColumnLayout = styled.div`
  display: grid;
  grid-template-columns: min-content auto;

  @media (min-width: 800px) {
    grid-template-columns: clamp(30%, 70px, 100%) auto;
  }
  @media (max-width: ${styles.screen.medium}) {
    grid-template-columns: 1fr;
    overflow: hidden;
    height: 100%;
  }
`;

const Main = styled.div`
  padding: 4rem 4rem 8rem;
  border-left: 1px solid ${styles.colors.primary10};
  overflow-y: auto;

  [class*="SdirRadioButtonGroup"] {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  @media (max-width: ${styles.screen.medium}) {
    padding: 0 2rem;
    max-height: 100%;
    overflow-y: auto;
  }
`;

const Sidebar = styled.aside`
  padding: 3rem 2rem;
  align-self: flex-start;
  position: sticky;
  top: 0;
  @media (min-width: 800px) {
    padding: 4rem 3rem;
  }
  @media (max-width: ${styles.screen.medium}) {
    padding: 1rem;
  }
`;
