import { FormContextProvider, useFormContext } from "../../context/formContext";
import { DescribeRiskStep } from "./Steps/DescribeRiskStep";
import { AssessBeforeMeasuresStep } from "./Steps/AssessBeforeMeasuresStep";
import { AddMeasuresStep } from "./Steps/AddMeasuresStep";
import { AssessAfterMeasuresStep } from "./Steps/AssessAfterMeasuresStep";
import { SummaryStep } from "./Steps/SummaryStep";
import {Risk, WorkTask} from "../../types/types";
import { useState } from "react";
import { SdirWizard } from "../SdirWizard";

export interface AddRiskWizardProps {
  isOpen: boolean;
  onClose: () => unknown;
  addRisk: (risk: Risk) => void;
  risk?: Risk;
  workTask?: WorkTask;
  setRisk: (risk: Risk | undefined) => void;
  currentStep: number;
  setCurrentStep: (to: number) => void;
}

const AddRiskWizardComponent = ({
  isOpen,
  onClose,
  addRisk,
  risk, 
  workTask,
  setRisk,
  currentStep,
  setCurrentStep,
}: AddRiskWizardProps) => {
  const [formValues, setFormValues] = useState(new Map<string, any>());

  return (
    <FormContextProvider
      getValue={(key: string) => formValues.get(key)}
      setValue={(key: string, value: any) => {
        const newFormValues = formValues;
        newFormValues.set(key, value);

        setRisk(Object.fromEntries(newFormValues) as Risk);
        setFormValues(newFormValues);
      }}
    >
      <SdirWizard
        visible={isOpen}
        title={`Legg til fare for "${workTask?.name}"`}
        category={``}
        preferredWidth={"1200px"}
        preferredHeight={"800px"}
        onCancel={onClose}
        onOK={() => {
          const risk = Object.fromEntries(formValues) as Risk;
          addRisk(risk);
          setRisk(undefined);
        }}
        okText={"Legg til"}
        cancelText={"Avbryt"}
        nextText={"Neste"}
        prevText={"Forrige"}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      >
        <DescribeRiskStep title={"Registrer fare"} />
        <AssessBeforeMeasuresStep title={`Vurder fare før tiltak`} risk={risk} />
        <AddMeasuresStep title={`Registrer tiltak`} risk={risk} />
        <AssessAfterMeasuresStep title={"Evaluer fare etter tiltak"} risk={risk} />
        <SummaryStep title={"Sammendrag"} risk={risk} />
      </SdirWizard>
    </FormContextProvider>
  );
};

export const AddRiskWizard = AddRiskWizardComponent;
