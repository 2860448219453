import { styles } from "@sdir/sds";
import styled, { css } from "styled-components";

export const StepContentWrapper = styled.div`
  h3,
  h4 {
    font-size: ${styles.font.size.lg};
    margin-bottom: 1rem;
  }
`;

export const InfoBox = styled.p<{type?: string | "warning" | "success"}>`
  padding: 2rem;
  background-color: ${styles.colors.alert.infoLight};
  border: 1px solid ${styles.colors.alert.infoDark};

  ${props => props.type === 'warning' && css`
    background-color: ${styles.colors.alert.warningLight};
    border-color: ${styles.colors.alert.warningDark};
  `}

  ${props => props.type === 'success' && css`
    background-color: ${styles.colors.alert.successLight};
    border-color: ${styles.colors.alert.successDark};
  `}
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem 2rem;
  border: 1px solid ${styles.colors.primary8};
  margin-bottom: 1.5rem;

  & + & {
    margin-top: 6rem;
  }
`;

export const CollapsableFields = styled.details`
  margin-bottom: 2.5rem;
  &[open] > summary {
    border-bottom-color: #FFF;
    svg {
      transform: rotate(180deg);
    }
  }

  &[open] > div {
    border-top-color: #FFF;
  }

  summary {
    border: 1px solid ${styles.colors.primary8};
    display: flex;
    position: relative;
    padding: .5em 1em;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }
    
  }

  & > div {
    border: 1px solid ${styles.colors.primary8};
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
`;


export const DatePickerContainer = styled.div`
  & [role="alert"] {
    // sr-only
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;