import {InfoBox, StepContentWrapper} from "../style";
import {Risk} from "../../../types/types";
import { RiskComponent } from "ui/Risk/Risk";

interface SummaryStepProps {
    title: string;
    risk?: Risk;
}

export const SummaryStep = ({title, risk}: SummaryStepProps) => {
    if(!risk) return <></>
    
    return (
        <StepContentWrapper>
            <h3>{title}</h3>
            <InfoBox type="success">
                Her er et sammendrag av din risikovurdering av faren 
                <strong> {risk?.name}</strong>.
            </InfoBox>
            <h4>Tiltak ({risk?.measures?.length})</h4>
            <RiskComponent risk={risk} allowCompletion={false} /> 
        </StepContentWrapper>
    )
};