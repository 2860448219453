import React, {useState} from "react";
import {EmptyState} from "ui/EmptyState";
import {Header} from "ui/Header";
import {VesselComponent} from "ui/Vessel";
import {RegisterVesselModal} from "ui/Modal/RegisterVesselModal";
import {useEffect} from "react";
import {AlertShort, SdirButton, styles} from "@sdir/sds";
import styled from "styled-components";
import {
    getVesselList,
    postVessel,
    updateVessel,
    deleteVessel,
} from "../../data/riskManagementApi";
import {defaultEmptyVessel, Vessel} from "types/types";
import {ConfirmDialog} from "../../ui/ConfirmDialog/ConfirmDialog";
import {ErrorMessageWrapper} from "./style";
import {LoadingModal} from "../../ui/LoadingModal/LoadingModal";
import {useGlobalContext} from "../../context/globalContext";
import {TransferOwnershipModal} from "../../ui/Modal/TransferOwnershipModal";

const HomePageComponent = () => {
    let [openAddVesselModalOpen, setOpenAddVesselModalOpen] = useState(false);
    let [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    let [transferOwnershipModalOpen, setTransferOwnershipModalOpen] = useState(false);
    let [activeVessel, setActiveVessel] = useState(defaultEmptyVessel);
    let [vesselList, setVesselList] = useState([]);
    let [editMode, setEditMode] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");
    let [isLoading, setIsLoading] = useState(true);
    let [hasError, setHasError] = useState(false);

    const globalContext = useGlobalContext();

    const getList = async () => {
        setIsLoading(true);
        try {
            const res = await getVesselList();
            setVesselList(res);
            setHasError(false);
        } catch (err: any) {
            setErrorMessage(err.toString());
            setHasError(true);
        }
        setIsLoading(false);
    };

    const findActiveVesselByID = (id: number) => {
        return vesselList.find((vessel: Vessel) => {
            return vessel.id === id;
        });
    };
    /** Hente fartøy fra backend */
    useEffect(() => {
        getList();
    }, [transferOwnershipModalOpen]);

    useEffect(() => {
        globalContext.setIsModalOpen(openAddVesselModalOpen || transferOwnershipModalOpen);
    }, [globalContext, openAddVesselModalOpen, transferOwnershipModalOpen])

    const handleSubmit = async (vessel: Vessel) => {
        try {
            if (editMode) {
                await updateVessel(vessel);
            } else {
                await postVessel(vessel);
            }
        } catch (err: any) {
            setErrorMessage(err.toString());
            return;
        }

        await getList();

        if (openAddVesselModalOpen) {
            setOpenAddVesselModalOpen(false);
        }
    };

    const onClickDelete = async (vessel?: Vessel) => {
        if (!vessel) return;

        setActiveVessel(vessel);
        setConfirmDeleteModalOpen(true);
    };

    const onCloseDeleteModal = () => {
        setActiveVessel(defaultEmptyVessel);
        setConfirmDeleteModalOpen(false);
    };

    const confirmDeleteVessel = async () => {
        if (!activeVessel.id) return;
        onCloseDeleteModal();
        await deleteVessel(activeVessel.id);
        await getList();
    };

    /** Åpner og lukker modal, henter nye verdier når modal lukkes */
    const toggleAddVessel = () => {
        setOpenAddVesselModalOpen(!openAddVesselModalOpen);
    };

    const toggleEditVessel = async (id: number) => {
        let vessel;
        try {
            vessel = findActiveVesselByID(id);
        } catch (err: any) {
            setErrorMessage(err.toString());
            return;
        }

        if (vessel) {
            setActiveVessel(vessel);
        }

        toggleAddVessel();
    };

    const toggleModal = async (id?: number) => {
        if (id) {
            toggleEditVessel(id);
            setEditMode(true);
        } else {
            setEditMode(false);
            setActiveVessel(defaultEmptyVessel);
            toggleAddVessel();
        }
    };

    let listItem = vesselList?.map((vessel: Vessel, index: number) => {
        return (
            <li key={vessel.id}>
                <VesselComponent
                    {...vessel}
                    onClickDelete={onClickDelete}
                    onClickEdit={toggleModal}
                />
            </li>
        );
    });

    return (
        <>
            <Header title="Mine risikovurderinger"/>
            <RegisterVesselModal
                isOpen={openAddVesselModalOpen}
                onClose={toggleModal}
                submitVessel={handleSubmit}
                activeVessel={activeVessel}
                editMode={editMode}
            ></RegisterVesselModal>
            <InfoWrapper>
                <div>
                    <h2>Finner du ikke ditt fartøy?</h2>
                    <p>Risikoverktøyet har blitt oppdatert, og dine risikovurderinger må overføres fra det gamle
                        verktøyet.</p>
                    <p><b>Merk:</b> Overføring av risikovurderinger kan bare gjennomføres én gang.</p>
                </div>
                <SdirButton type="default" onClick={() => setTransferOwnershipModalOpen(true)}>
                    Overfør fra gammelt verktøy
                </SdirButton>
            </InfoWrapper>
            {!hasError && (
                vesselList.length > 0 ? (
                    <>
                        <SdirButton type="primary" onClick={() => toggleModal()}>
                            Legg til fartøy
                        </SdirButton>
                        <VesselListWrapper>{listItem}</VesselListWrapper>
                    </>
                ) : (
                    !isLoading && <EmptyState
                        title="Ingen risikovurderinger registert"
                        description="Du har ingen risikovurderinger registrert enda. For å kunne gjøre en risikovurdering må du først legge til et fartøy."
                        buttonText="Legg til fartøy"
                        buttonOnClick={() => toggleAddVessel()}
                    ></EmptyState>
                ))}

            {isLoading && !hasError && <LoadingModal/>}

            <ErrorMessageWrapper
                visible={errorMessage.length > 0}
                onClick={(e) => setErrorMessage("")}
            >
                <AlertShort type={"error"} text={errorMessage}></AlertShort>
            </ErrorMessageWrapper>
            <ConfirmDialog
                visible={confirmDeleteModalOpen}
                onCancel={() => onCloseDeleteModal()}
                onConfirm={() => confirmDeleteVessel()}
                title={`Bekreft sletting`}
                text={`Er du sikker på at du vil slette ${activeVessel.name}? Denne handlingen kan ikke reverseres.`}
                confirmText={"Slett"}
                cancelText={"Avbryt"}
                confirmButtonType="danger"
            />
            <TransferOwnershipModal isOpen={transferOwnershipModalOpen}
                                    onCancel={() => setTransferOwnershipModalOpen(false)}></TransferOwnershipModal>
        </>
    );
};

const VesselListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  li:first-child {
    margin-top: 4rem;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  max-width: 100%;
  margin-inline: auto;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2em;
  background-color: ${styles.colors.alert.infoLight};
  border: 1px solid ${styles.colors.alert.infoDark};
  margin-bottom: 1.5em;

  div {
    max-width: 60ch;
  }

  h2 {
    margin-top: 0;
    margin-bottom: .5em;
  }

  p {
    margin: 0;
  }
`;

export const HomePage = React.memo(HomePageComponent);
