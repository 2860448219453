import {
  SdirButton,
  SdirCheckbox,
  StepsIndicator,
  styles,
  TextField,
} from "@sdir/sds";
import { useState } from "react";
import styled from "styled-components";
import { FilterList } from "ui/FilterList";
import { ListAccordion } from "ui/ListAccordion";
import { SdirFormModal } from "./SdirFormModal";

export default {
  title: "Modal",
  component: SdirFormModal,
};

export function Default(args: any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SdirButton onClick={() => setIsOpen(!isOpen)}>Åpne modal</SdirButton>
      <SdirFormModal
        title={args.title || "Tittel"}
        visible={isOpen}
        cancelText={args.cancelText || "Avbryt"}
        okText={args.okText || "Legg til"}
        width={args.width}
        height={args.height}
        onCancel={() => setIsOpen(!isOpen)}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
          cupiditate amet iure et fuga obcaecati consectetur dignissimos harum,
          libero iusto officia expedita saepe omnis ducimus delectus ut non, ea
          corrupti.
        </p>
        <TextField id={""} label={"Tekst"}></TextField>
      </SdirFormModal>
    </>
  );
}

export function WithSidebar(args: any) {
  const [isOpen, setIsOpen] = useState(false);
  let items = [
    { id: 1, name: "Juksa- og dorgefiske" },
    { id: 2, name: "Notfiske" },
    { id: 3, name: "Snurrevadsfiske" },
    { id: 4, name: "Teinefiske" },
    { id: 5, name: "Linefiske" },
    { id: 6, name: "Garnfiske" },
    { id: 7, name: "Liten arbeidsbåt" },
    { id: 8, name: "Servicebåt" },
    { id: 9, name: "Arbeidsbåt" },
    { id: 10, name: "Egendefinerte arbeidsoppgaver" },
  ];
  return (
    <>
      <SdirButton onClick={() => setIsOpen(!isOpen)}>Åpne modal</SdirButton>
      <SdirFormModal
        title={args.title || "Tittel"}
        visible={isOpen}
        cancelText={args.cancelText || "Avbryt"}
        okText={args.okText || "Legg til"}
        width={args.width}
        height={args.height}
        onCancel={() => setIsOpen(!isOpen)}
      >
        <Top>
          <h3>Velg arbeidsoppgaver</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam odio,
            officiis fugiat perferendis distinctio aspernatur exercitationem
            dicta perspiciatis dolor eligendi architecto amet, aliquid dolorem
            autem eius facilis non et aperiam.
          </p>
        </Top>
        <ColumnLayout>
          <Sidebar>
            <FilterList
              title="Vis bare arbeidsoppgaver for"
              listItems={items}
              addFilter={() => {}}
              removeFilter={() => {}}
            ></FilterList>
          </Sidebar>
          <Main>
            <TaskSummary>Viser 80 av totalt 124 arbeidsoppgaver</TaskSummary>
            <ListAccordion title="Haling av redskaper (0 av 6)">
              <CheckBoxList>
                <SdirCheckbox title="Ta ombord garnstaur" />
                <SdirCheckbox title="Haling av garnlenke" />
                <SdirCheckbox title="Ombordtakning av anker/dregg" />
                <SdirCheckbox title="Haling av iletau" />
                <SdirCheckbox title="Uttining og bløgging av fisk" />
                <SdirCheckbox title="Fisken slippes over i mottaksbinge på dekk eller i lasterom" />
              </CheckBoxList>
            </ListAccordion>
            <ListAccordion title="Klargjøring av fartøy (0 av 6)">
              <CheckBoxList>
                <SdirCheckbox title="Ta ombord garnstaur" />
                <SdirCheckbox title="Haling av garnlenke" />
                <SdirCheckbox title="Ombordtakning av anker/dregg" />
                <SdirCheckbox title="Haling av iletau" />
                <SdirCheckbox title="Uttining og bløgging av fisk" />
                <SdirCheckbox title="Fisken slippes over i mottaksbinge på dekk eller i lasterom" />
              </CheckBoxList>
            </ListAccordion>
            <ListAccordion title="Arbeid i lasterom (0 av 14)">
              <CheckBoxList>
                <SdirCheckbox title="Ta ombord garnstaur" />
                <SdirCheckbox title="Haling av garnlenke" />
                <SdirCheckbox title="Ombordtakning av anker/dregg" />
                <SdirCheckbox title="Haling av iletau" />
                <SdirCheckbox title="Uttining og bløgging av fisk" />
                <SdirCheckbox title="Fisken slippes over i mottaksbinge på dekk eller i lasterom" />
                <SdirCheckbox title="Haling av iletau" />
                <SdirCheckbox title="Uttining og bløgging av fisk" />
                <SdirCheckbox title="Fisken slippes over i mottaksbinge på dekk eller i lasterom" />
                <SdirCheckbox title="Haling av iletau" />
                <SdirCheckbox title="Uttining og bløgging av fisk" />
                <SdirCheckbox title="Fisken slippes over i mottaksbinge på dekk eller i lasterom" />
              </CheckBoxList>
            </ListAccordion>
          </Main>
        </ColumnLayout>
      </SdirFormModal>
    </>
  );
}

const TaskSummary = styled.p`
  font-size: ${styles.font.size.md};
  color: ${styles.colors.font.text85};
  margin: 0;
`;

const CheckBoxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 25% auto;
`;

const Main = styled.div`
  padding: 4rem 4rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const Top = styled.div`
  padding: 3rem;
  background-color: ${styles.colors.background.light};
  border-bottom: 1px solid ${styles.colors.primary10};
  h3 {
    margin-top: 0;
  }
`;

const Sidebar = styled.aside`
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-right: 1px solid ${styles.colors.primary10};
`;
