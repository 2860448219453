import {useEffect} from "react";

export const useFocusLock = (elementRef?: HTMLElement, modalPageNumber?: number) => {
    useEffect(() => {
        if (!elementRef) return;

        const focusableElements =
            elementRef!.querySelectorAll<HTMLElement>(
                ':not(.not-active) button, :not(.not-active) [href], :not(.not-active) input, :not(.not-active) select, :not(.not-active) textarea, :not(.not-active) [tabindex]:not([tabindex="-1"])'
            );
        const firstFocusable = focusableElements![0];
        const lastFocusable = focusableElements![focusableElements!.length - 1];
        firstFocusable.focus();

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Tab") {
                if (event.shiftKey) {
                    if (document.activeElement === firstFocusable) {
                        event.preventDefault();
                        lastFocusable.focus();
                    }
                } else {
                    if (document.activeElement === lastFocusable) {
                        event.preventDefault();
                        firstFocusable.focus();
                    }
                }
            }
        };
        elementRef.addEventListener("keydown", handleKeyDown);
        
        return () => elementRef.removeEventListener("keydown", handleKeyDown);
    }, [elementRef, modalPageNumber])
}
