import React from "react";
import {SdiCircleArrow, SdirButton, styles} from "@sdir/sds";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {CreateVesselPdf} from "../../data/riskManagementApi";

export interface HeaderProps {
  title: string;
  hasBackLink?: boolean;
  vesselId?: number;
  metaData?: {
    vessel?: string;
    created?: string;
    edited?: string;
  };
}

const HeaderComponent = ({ hasBackLink, title, vesselId, metaData }: HeaderProps) => {
  return (
    <HeaderWrapper>
      {hasBackLink && (<Link to={"/app"}><SdiCircleArrow direction="left" />Tilbake til oversikt</Link>)}
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderDataRow>
        {metaData && (
            <HeaderMetadata>
              <div>
                <dt>Fartøy</dt>
                <dd>{metaData.vessel}</dd>
              </div>
              <div>
                <dt>Opprettet</dt>
                <dd>{metaData.created}</dd>
              </div>
              <div>
                <dt>Sist endret</dt>
                <dd>{metaData.edited}</dd>
              </div>
            </HeaderMetadata>
        )}
        {vesselId && 
          <DownloadButtonWrapper>
              <SdirButton onClick={async (e) => {
                  e.preventDefault();
                  const pdf = await CreateVesselPdf(vesselId);
                  var file = window.URL.createObjectURL(pdf);
                  window.open(file, "_blank");
              }}>
                  Last ned
              </SdirButton>
          </DownloadButtonWrapper>
        }
      </HeaderDataRow>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  padding-top: 4rem;
  border-bottom: 1px solid ${styles.colors.primary8};
  margin-bottom: 6rem;
  & > a {
    font-size: 18px;
    color: ${styles.colors.primary};
    display: inline-flex;
    align-items: center;
    gap: 1.25rem;
    &:visited {
      color: ${styles.colors.primary};
    }
  }

  svg {
    margin-top: -4px; // The font is made in a way that it's not possible to vertically center align with the icon. This nudges the icon a bit upwards. 
  }

  path {
    fill: ${styles.colors.primary};
  }
`;

const HeaderTitle = styled.h2`
  font-size: ${styles.font.size.xxxl};
`;

const HeaderMetadata = styled.dl`
  margin-top: 2rem;
  padding-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  font-size: ${styles.font.size.md};

  dt {
    text-transform: uppercase;
    color: ${styles.colors.font.text85};
  }

  dd {
    font-size: ${styles.font.size.md};
  }
`;

const HeaderDataRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DownloadButtonWrapper = styled.div`
  margin-left: 4rem;
  margin-top: 2rem;
`;

export const Header = React.memo(HeaderComponent);
