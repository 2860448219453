import React from "react";
import styled from "styled-components";
import { styles, SdirButton } from "@sdir/sds";

export interface EmptyStateProps {
  icon?: React.ReactElement;
  title: string;
  description: string;
  buttonText: string;
  buttonOnClick: () => unknown;
  children?: React.ReactNode;
}

const EmptyStateComponent = ({
  icon,
  title,
  description,
  buttonText,
  buttonOnClick,
  children,
}: EmptyStateProps) => {
  return (
    <EmptyStateWrapper>
      {icon && icon}
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
      <SdirButton type="primary" onClick={buttonOnClick}>
        {buttonText}
      </SdirButton>
      {children}
    </EmptyStateWrapper>
  );
};

const EmptyStateWrapper = styled.div`
  background-color: ${styles.colors.background.secondary};
  border-radius: ${styles.card.borderRadius};
  box-shadow: ${styles.card.boxShadow};
  margin-bottom: ${styles.card.marginBottom};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 6rem;
  & > * {
    max-width: 60ch;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: 1em;
  }
`;

export const EmptyState = React.memo(EmptyStateComponent);
