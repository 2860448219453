import React, { useEffect, useState } from "react";
import { Loader, SdirButton, styles, TextField } from "@sdir/sds";
import styled from "styled-components";
import { SdirFormModal } from "../../ui/SdirModal";
import { requestOwnershipTransfer, transferOwnerships } from "../../data/riskManagementApi";


interface ITransferOwnershipModalProps {
    isOpen: boolean;
    onCancel: () => void;
}

export const TransferOwnershipModal = (props: ITransferOwnershipModalProps) => {
    const [email, setEmail] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [showConfirmField, setShowConfirmField] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sentNewConfirmationCode, setSentNewConfirmationCode] = useState(false);

    const { isOpen, onCancel } = props;

    useEffect(() => {
        setEmail("");
        setConfirmationCode("");
        setShowConfirmField(false);
        setHasError(false);
        setCompleted(false);
    }, [isOpen])

    const sendConfirmationCode = async () => {
        if (!email) {
            setHasError(true);
            return;
        }

        setIsLoading(true);

        await requestOwnershipTransfer(email);
        setIsLoading(false);
        setShowConfirmField(true);
        setHasError(false);
    }

    return (
        <SdirFormModal visible={isOpen}
            width="600px"
            cancelText={"Avbryt"}
            title={"Overfør risikovurderinger"}
            okText={showConfirmField ? completed ? "Ok" : "Bekreft overføring" : "Send bekreftelseskode"}
            isLoading={isLoading}
            onSubmit={async (e) => {
                if (completed) {
                    onCancel();
                    return;
                }

                if (!showConfirmField) {
                    sendConfirmationCode();
                    return;
                }

                e.preventDefault();

                if (confirmationCode) {
                    try {
                        const result = await transferOwnerships(email, confirmationCode);
                    } catch (e) {
                        setHasError(true);
                        return;
                    }
                }

                setCompleted(true);
            }}
            onCancel={onCancel}>
            <ModalContentWrapper>
                {!completed && !showConfirmField && !isLoading &&
                    <>
                        <p>Hvis du har gjort risikovurderinger i den gamle versjonen av risikoverktøyet, kan du overføre
                            disse ved å bekrefte din e-post. </p>
                        <InputContainer>
                            <ErrorMessage hasError={hasError} htmlFor={"email-input"}>E-post kan ikke være
                                tom</ErrorMessage>
                            <TextField
                                label="E-post"
                                name={"email"}
                                id={"email-input"}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                value={email}
                            />
                        </InputContainer></>}
                {!completed && showConfirmField && !isLoading && 
                    <InputContainer>
                        <p>Bekreftelseskode er forsøkt sendt til: <strong>{email}</strong></p>

                        <InfoWrapper>
                            Har du ikke fått din kode? Sjekk i søppelpost, eller send ny
                            bekreftelseskode. Dersom eposten ikke er registrert i det gamle verktøyet,
                            vil du heller ikke få en epost.
                        </InfoWrapper>
                        {sentNewConfirmationCode && <span>Ny kode sendt</span>}
                        <NewConfirmationCodeButton
                            onClick={async () => {
                                setSentNewConfirmationCode(false);
                                await sendConfirmationCode();
                                setSentNewConfirmationCode(true);
                            }}><p>Send ny bekreftelseskode</p></NewConfirmationCodeButton>
                        <ErrorMessage hasError={hasError} htmlFor={"confirm-input"}>Feil kode</ErrorMessage>
                        <TextField
                            label="Bekreftelseskode"
                            name={"confirmationCode"}
                            id={"confirm-input"}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                            value={confirmationCode ?? ""}
                        />
                    </InputContainer>
                    
                }
                {isLoading &&
                    <Loader />
                }
                {completed && <p>Dine fartøy er nå overført</p>}
            </ModalContentWrapper>
        </SdirFormModal>
    )
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  flex: 1;
  margin-bottom: 1em;
`;

const ModalContentWrapper = styled.div`
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ErrorMessage = styled.label<{ hasError: boolean }>`
  display: ${props => props.hasError ? "block" : "none"};
  color: red;
`;

const InfoWrapper = styled.div`
  display: flex;
  max-width: 100%;
  margin-inline: auto;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1em;
  background-color: ${styles.colors.alert.infoLight};
  border: 1px solid ${styles.colors.alert.infoDark};
  margin-bottom: 1.5em;

  div {
    max-width: 60ch;
  }

  h2 {
    margin-top: 0;
    margin-bottom: .5em;
  }

  p {
    margin: 0;
  }
`;

const NewConfirmationCodeButton = styled.button`
  background: none;
  border: none;
  text-align: left;
  font-size: 1em;
  color: ${styles.colors.primary4};
  cursor: pointer;
  margin-bottom: 0.5em;
  text-decoration: underline;
  
  p {
    margin: 0;
  }
`;