import { styles } from "@sdir/sds";
import styled from "styled-components";
import { FilterListItem } from "./FilterListItem";

export interface FilterListProps {
  title: string;
  listItems: { id: number; name: string }[];
  addFilter: (id: number) => void;
  removeFilter: (id: number) => void;
}

const FilterListComponent = ({
  title,
  listItems,
  addFilter,
  removeFilter,
}: FilterListProps) => {
  const items = listItems.map((item) => {
    return (
      <FilterListItem
        id={item.id}
        name={item.name}
        key={item.id}
        onCheck={(isChecked) => {
          if (isChecked) {
            addFilter(item.id);
          } else {
            removeFilter(item.id);
          }
        }}
      ></FilterListItem>
    );
  });

  return (
    <Container>
      <Title>{title}</Title>
      <List>{items}</List>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.h3`
  font-size: ${styles.font.size.md};
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${styles.colors.primary10};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FilterList = FilterListComponent;
