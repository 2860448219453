import React, { useEffect, useState } from 'react'
import { SdirButton, styles } from '@sdir/sds'
import { SdirFormModal } from '../SdirModal/SdirFormModal'
import styled from 'styled-components'
import { Vessel } from 'types/types'
import TextField from 'ui/TextField/TextField'

export interface ModalProps {
  isOpen: boolean
  editMode: boolean
  onClose: () => unknown
  submitVessel: (vessel: Vessel) => void
  activeVessel: Vessel
}

const RegisterVesselComponent = ({
  isOpen,
  onClose,
  submitVessel,
  activeVessel,
  editMode,
}: ModalProps) => {
  const [callSign, setCallSign] = useState<string>('');
  const [callSignError, setCallSignError] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [buildYear, setBuildYear] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [length, setLength] = useState<string>('');
  const [depth, setDepth] = useState<string>('');
  const [width, setWidth] = useState<string>('');
  const [numPersonnel, setNumPersonnel] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');

  const [error, setError] = useState('')

  const [blurCount, setBlurCount] = useState(0);

  useEffect(() => {
    setName(activeVessel.name ?? "");
    setCallSign(activeVessel.callSign ?? "");
    setBuildYear(activeVessel.buildYear?.toString() ?? "");
    setWeight(activeVessel.weight?.toString() ?? "");
    setLength(activeVessel.length?.toString() ?? "");
    setDepth(activeVessel.depth?.toString() ?? "");
    setWidth(activeVessel.width?.toString() ?? "");
    setNumPersonnel(activeVessel.numPersonnel?.toString() ?? "");
    setContactName(activeVessel.contactName ?? "");
    setContactEmail(activeVessel.contactEmail ?? "");
    setContactPhone(activeVessel.contactPhone ?? "");
  }, [activeVessel]);

  //midlertig metode som returnerer feil hvis tekstinput ikke er "LETJ"
  //må integreres med backend hvor input-felt verdier blir satt hvis fartøyet finnes evnt returner feilmelding
  const findVessel = async () => {
    let res = await fetch(`https://localhost:7089/RiskManagement/GetVessels`)
    const list = await res.json()
    return list[0]
  }

  const autoFill = async (e: any) => {
    e.preventDefault()
    const vessel = await findVessel()
    // setVessel(vessel);
  }

  return (
    <>
      <SdirFormModal
        visible={isOpen}
        onCancel={onClose}
        cancelText="Avbryt"
        okText={editMode ? 'Oppdater' : 'Legg til'}
        width="700px"
        height="900px"
        title={editMode ? "Rediger fartøy" : "Legg til nytt fartøy"}
        onSubmit={(e) => {
          e.preventDefault()

          submitVessel({
            id: activeVessel.id,
            name,
            callSign,
            buildYear: buildYear ? Number(buildYear) : undefined,
            length: length ? Number(length) : undefined,
            depth: depth ? Number(depth) : undefined,
            width: width ? Number(width) : undefined,
            weight: weight ? Number(weight) : undefined,
            numPersonnel: numPersonnel ? Number(numPersonnel) : undefined,
            contactPhone,
            contactEmail,
            contactName,
          } as Vessel)
        }}
      >
        <FormModalContentWrapper>
          <FieldSet>
            <legend>Identitet</legend>
            <div>
              <TextFieldGroup>
                <TextField
                  label="Kjenningssignal"
                  id="vesselSignal"
                  name="callSign"
                  type="text"
                  value={callSign}
                  onChange={(e) => setCallSign(e.target.value)}
                  invalid={callSignError}
                  error={error}
                  required={true}
                />
                {/* <SdirButton onClick={(e) => autoFill(e)}>Finn fartøy</SdirButton> */}
              </TextFieldGroup>
              <TextField
                label="Navn på fartøy"
                id="vesselName"
                type="text"
                helptext="Hva heter fartøyet ditt?"
                value={name}
                name="name"
                onBlur={() => setBlurCount(blurCount + 1)}
                onChange={(e) => setName(e.target.value)}
                required={true}
              />
              <TextField
                label="Byggeår (valgfritt)"
                id="vesselYear"
                type="number"
                value={buildYear ?? ""}
                name="buildYear"
                onBlur={() => setBlurCount(blurCount + 1)}
                onChange={(e) => setBuildYear(e.target.value)}
              />
            </div>
          </FieldSet>
          <FieldSet>
            <legend>Måldata</legend>
            <div>
              <TextField
                label="Bruttotonnasje (valgfritt)"
                id="vesselTonnage"
                type="number"
                value={weight ?? ""}
                name="weight"
                onChange={(e) => setWeight(e.target.value)}
                helptext="Oppgi i tonn"
              />
              <TextField
                label="Bredde (valgfritt)"
                id="vesselWidth"
                type="number"
                name="width"
                onChange={(e) => setWidth(e.target.value)}
                value={width ?? ""}
              />
              <TextField
                label="Lengde (valgfritt)"
                id="vesselLength"
                type="number"
                value={length ?? ""}
                name="length"
                onChange={(e) => setLength(e.target.value)}
              />
              <TextField
                label="Dybde (valgfritt)"
                id="vesselDepth"
                type="number"
                name="depth"
                onChange={(e) => setDepth(e.target.value)}
                value={depth ?? ""}
              />
              <TextField
                  label="Antall personer om bord (valgfritt)"
                  id="vesselNumberOfPeople"
                  type="number"
                  name="numPersonnel"
                  onChange={(e) => setNumPersonnel(e.target.value)}
                  value={numPersonnel ?? ""}
              />
            </div>
          </FieldSet>
          <FieldSet>
            <legend>Kontaktperson</legend>
            <div>
              <TextField
                label="Navn*"
                id="vesselContactName"
                type="text"
                name="contactName"
                onChange={(e) => setContactName(e.target.value)}
                helptext="Fullt navn"
                value={contactName}
                required={true}
              />
              <TextField
                label="E-post*"
                id="vesselContactEmail"
                type="email"
                name="contactEmail"
                onChange={(e) => setContactEmail(e.target.value)}
                value={contactEmail}
                required={true}
              />
              <TextField
                label="Telefonnummer (valgfritt)"
                id="vesselContactPhone"
                name="contactPhone"
                onChange={(e) => setContactPhone(e.target.value)}
                type="tel"
                value={contactPhone}
              />
            </div>
          </FieldSet>
        </FormModalContentWrapper>
      </SdirFormModal>
    </>
  )
}

const FormModalContentWrapper = styled.div`
  padding: 4rem 3rem;
`

const FieldSet = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;
  margin-bottom: 6rem;
  legend {
    font-size: ${styles.font.size.xl};
    margin-bottom: 3rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
`

const TextFieldGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

export const RegisterVesselModal = React.memo(RegisterVesselComponent)
