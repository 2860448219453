import { WorkTask } from "types/types";
import styled from "styled-components";
import { SdiCloseCross, styles } from "@sdir/sds";

export interface CheckBoxProps {
  name: string;
  id: number;
  onChange: Function;
  onRemove?: Function;
  checked: boolean;
}

const CheckBoxComponent = ({
  name,
  id,
  onChange,
  onRemove,
  checked,
}: CheckBoxProps) => {
  return (
    <CheckBoxWrapper>
      <div>
        <input
          type="checkbox"
          name={id.toString()}
          id={id.toString()}
          onChange={(e) => onChange(e.target.checked, id)}
          checked={checked}
        />
        <label htmlFor={id.toString()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="15"
            viewBox="0 0 19 15"
            data-testid="checkmark"
          >
            <path
              d="M43.042,32.109l-9.223,9.38-2.679-2.175L29.529,38l-2.576,3.275,5.732,4.664,1.443,1.166L45.953,35.083Z"
              transform="translate(-26.953 -32.109)"
            ></path>
          </svg>
          <span className="checkbox-text-wrapper">
            <span className="checkbox-text">{name}</span>
          </span>
        </label>
      </div>
      {onRemove && (
        <RemoveTaskButton
          onClick={(e) => {
            e.preventDefault();
            onRemove(id);
          }}
        >
          Fjern
          <SdiCloseCross color={styles.colors.alert.errorDark} height="24" />
        </RemoveTaskButton>
      )}
    </CheckBoxWrapper>
  );
};

const RemoveTaskButton = styled.button`
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: 1.75rem;
  font-style: inherit;
  font-weight: 600;
  line-height: inherit;
  padding: 0;
  color: ${styles.colors.alert.errorDark};
  margin-left: 16px;
  margin-bottom: 2px;
  padding: 4px 8px;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: ${styles.colors.alert.errorLight};
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  
  input {
    margin: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  input:checked {
    background-color: rgb(0, 60, 105);
  }

  label {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    gap: 12px;
  }
  
  label:hover {
    cursor: pointer;
  }
  
  label:hover::after {
    box-shadow: rgb(102 138 165) 0px 0px 3px;
  }
  
  :has(:focus-visible) label {
    transition: 0s;
    outline: 4px solid #1A9AC5 !important;
    outline-offset: 2px !important;
  }

  label:after {
    content: "";
    display: inline-flex;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    border: 1px solid rgb(102, 138, 165);
    transition: all 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    flex-shrink: 0;
  }

  input:checked + label:after {
    background-color: rgb(0, 60, 105);
  }

  label svg {
    display: none;
  }

  input:checked + label svg {
    display: block;
    fill: white;
    visibility: visible;
    transition: all 0.3s ease 0s;
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 7px;
  }

  .checkbox-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 30px;
  }
`;

export const CheckBox = CheckBoxComponent;
