import React, {useEffect, useState} from "react";
import {Header} from "ui/Header";
import {EmptyState} from "ui/EmptyState";
import {AddTaskModal} from "ui/Modal/AddTaskModal";
import {TaskList} from "ui/Task/TaskList";
import CategoryMenu from "ui/Menu/CategoryMenu";
import styled from "styled-components";
import {AlertShort, SdirButton} from "@sdir/sds";
import {
    addRiskToWorkTask,
    getRiskAssessment,
    removeWorkTasks,
    getAssessmentCategories,
    getWorkTaskChoiceList, updateRisk, deleteRisk,
} from "../../data/riskManagementApi";
import {Risk, Vessel, WorkProcess, WorkTask} from "../../types/types";
import {useParams} from "react-router";
import {VesselContextProvider} from "../../context/vesselContext";
import {FilterListItemProps} from "ui/FilterList";
import {AddRiskWizard} from "../../ui/AddRiskWizard";
import {ConfirmDialog} from "../../ui/ConfirmDialog/ConfirmDialog";
import {formatDateStringShort} from "../../helpers/dateHelpers";
import {EditRiskWizard} from "ui/AddRiskWizard/EditRiskWizard";
import {ErrorMessageWrapper} from "./style";
import {LoadingModal} from "../../ui/LoadingModal/LoadingModal";
import {useGlobalContext} from "../../context/globalContext";

const AssessmentPageComponent = () => {
    const [addTaskModalIsOpen, setAddTaskModalIsOpen] = useState(false);
    const [addRiskWizardIsOpen, setAddRiskWizardIsOpen] = useState(false);
    const [editRiskWizardIsOpen, setEditRiskWizardIsOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState<number>();
    const [workProcesses, setWorkProcesses] = useState<WorkProcess[]>([]);
    const [currentVessel, setCurrentVessel] = useState<Vessel>([]);
    const [assessmentCategories, setAssessmentCategories] = useState<FilterListItemProps[]>([]);
    const [taskChoices, setTaskChoices] = useState<WorkProcess[]>([]);
    const [currentRisk, setCurrentRisk] = useState<Risk | undefined>();
    const [wizardStep, setWizardStep] = useState(0);
    const [confirmDeleteWorkTaskModalOpen, setConfirmDeleteWorkTaskModalOpen] = useState(false);
    const [activeWorkTask, setActiveWorkTask] =
        useState<WorkTask | undefined>(undefined);
    const [confirmDeleteRiskModalOpen, setConfirmDeleteRiskModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const {vesselId} = useParams();
    const vesselIdNumber = Number(vesselId);

    const globalContext = useGlobalContext();
    
    useEffect(() => {
        fetchTasks();
        fetchTaskChoiceList();
    }, []);

    const fetchTasks = async () => {
        setIsLoading(true);
        try {
            if (!isLoading) {
                const riskAssessment = await getRiskAssessment(vesselIdNumber);
                setCurrentVessel(riskAssessment.vessel);
                const assessmentCategories = await getAssessmentCategories();
                setAssessmentCategories(assessmentCategories);
                setWorkProcesses(riskAssessment.workProcesses);
                setHasError(false);
            }
        } catch (err: any) {
            setHasError(true)
            setErrorMessage(err.toString())
        }
        setIsLoading(false)
    }

    const fetchTaskChoiceList = async () => {
        const response = await getWorkTaskChoiceList(vesselIdNumber);
        setTaskChoices(response);
    };

    const filterAlreadyAddedWorkTasks = (fromWorkProcess: WorkProcess) => {
        return fromWorkProcess?.workTasks?.filter(
            (workTask) =>
                !workProcesses
                    .flatMap((workProcess) => workProcess.workTasks)
                    .some((x) => workTask.id === x.id)
        )
    }

    const toggleAddTask = () => {
        setAddTaskModalIsOpen(!addTaskModalIsOpen);
    };

    const toggleAddRiskWizard = () => {
        setAddRiskWizardIsOpen(!addRiskWizardIsOpen);
    };
    useEffect(() => {
        if (!addTaskModalIsOpen) {
            fetchTasks();
        }
        
        globalContext.setIsModalOpen(addTaskModalIsOpen);
    }, [addTaskModalIsOpen, globalContext]);
    
    useEffect(() => {
        globalContext.setIsModalOpen(addRiskWizardIsOpen);
    }, [addRiskWizardIsOpen, globalContext])

    useEffect(() => {
        globalContext.setIsModalOpen(editRiskWizardIsOpen);
    }, [editRiskWizardIsOpen, globalContext])


    const confirmDeleteWorkTask = async () => {
        setConfirmDeleteWorkTaskModalOpen(false);

        if (!activeWorkTask?.id) return;

        await removeWorkTasks([activeWorkTask.id], vesselIdNumber);
        await fetchTasks();
    };

    const confirmDeleteRisk = async () => {
        setConfirmDeleteRiskModalOpen(false);

        if (!currentRisk?.id) return;

        await deleteRisk(currentRisk.id, vesselIdNumber);
        await fetchTasks();
    };

    return (
        <VesselContextProvider
            value={{
                currentVesselId: vesselIdNumber,
                openRiskModalForWorkTask: (workTask) => {
                    setActiveWorkTask(workTask);
                    setCurrentRisk(undefined);
                    setWizardStep(0);
                    toggleAddRiskWizard();
                },
                openEditRiskModal: (risk: Risk, workTask: WorkTask) => {
                    setActiveWorkTask(workTask);
                    setCurrentRisk(risk);
                    setWizardStep(4);
                    setEditRiskWizardIsOpen(true);
                },
                currentRisk: currentRisk,
                setCurrentRisk: setCurrentRisk,
                openConfirmDeleteModalForWorkTask: (workTask: WorkTask) => {
                    setActiveWorkTask(workTask);
                    setConfirmDeleteWorkTaskModalOpen(true);
                },
                openConfirmDeleteModalForRisk: (risk: Risk) => {
                    setCurrentRisk(risk);
                    setConfirmDeleteRiskModalOpen(true);
                },
            }}
        >
            <Header
                hasBackLink={true}
                title="Risikovurdering"
                vesselId={currentVessel.id}
                metaData={{
                    vessel: currentVessel.callSign ?? "-",
                    created: currentVessel.dateCreated ? formatDateStringShort(new Date(currentVessel.dateCreated)) : "-",
                    edited: currentVessel.dateUpdated ? formatDateStringShort(new Date(currentVessel.dateUpdated)) : "-",
                }}
            />
            {!hasError && (
                workProcesses && workProcesses.length > 0 ? (
                    <>
                        <PageButtons>
                            <SdirButton type="primary" onClick={toggleAddTask}>
                                Legg til flere arbeidsoppgaver
                            </SdirButton>
                        </PageButtons>
                        <GridColumns>
                            <GridColumn>
                                <CategoryMenu
                                    setActiveCategory={setActiveCategory}
                                    categories={workProcesses.map((workProcess) => {
                                        return {
                                            id: workProcess.id,
                                            categoryName: workProcess.name,
                                            numberOfItems: workProcess.workTasks.length,
                                        }
                                    })}
                                />
                            </GridColumn>
                            <GridColumn>
                                <>
                                    <TaskList
                                        taskList={workProcesses
                                            .filter((x) => !activeCategory || activeCategory === x.id)
                                            .flatMap((workProcess) => workProcess.workTasks)}
                                    ></TaskList>
                                </>
                            </GridColumn>
                        </GridColumns>
                    </>
                ) : (!isLoading &&
                    <EmptyState
                        title="Du har ingen arbeidsoppgaver"
                        description="Du har ingen arbeidsoppgaver registrert enda. For å kunne gjøre en risikovurdering må du først legge til en arbeidsoppgave."
                        buttonText="Legg til arbeidsoppgave"
                        buttonOnClick={toggleAddTask}
                    ></EmptyState>
                )
            )}

            {isLoading && !hasError && <LoadingModal />}
            
            <ErrorMessageWrapper visible={errorMessage.length > 0} onClick={(e) => setErrorMessage('')}>
                <AlertShort type={'error'} text={errorMessage}></AlertShort>
            </ErrorMessageWrapper>

            <AddTaskModal
                isOpen={addTaskModalIsOpen}
                onClose={toggleAddTask}
                assessmentCategories={assessmentCategories}
                taskChoices={taskChoices.map((taskChoice) => {
                    return {
                        ...taskChoice,
                        workTasks: filterAlreadyAddedWorkTasks(taskChoice),
                    };
                })}
                vesselID={vesselIdNumber}
            ></AddTaskModal>
            {addRiskWizardIsOpen && <AddRiskWizard
                isOpen={addRiskWizardIsOpen}
                onClose={toggleAddRiskWizard}
                workTask={activeWorkTask}
                addRisk={async (risk: Risk) => {
                    if(!activeWorkTask) return;
                    
                    const addedRisk = await addRiskToWorkTask(risk, activeWorkTask.id, vesselIdNumber);

                    toggleAddRiskWizard();
                    await fetchTasks();
                }}
                setRisk={setCurrentRisk}
                risk={currentRisk}
                currentStep={wizardStep}
                setCurrentStep={setWizardStep}
            ></AddRiskWizard>}
            {editRiskWizardIsOpen && <EditRiskWizard
                isOpen={editRiskWizardIsOpen}
                workTask={activeWorkTask}
                onClose={() => setEditRiskWizardIsOpen(false)}
                editRisk={async (risk: Risk) => {
                    await updateRisk(risk, vesselIdNumber);
                    await fetchTasks();

                    setEditRiskWizardIsOpen(false);
                }}
                setRisk={setCurrentRisk}
                risk={currentRisk}
                currentStep={wizardStep}
                setCurrentStep={setWizardStep}
            ></EditRiskWizard>}
            <ConfirmDialog
                visible={confirmDeleteWorkTaskModalOpen}
                onCancel={() => setConfirmDeleteWorkTaskModalOpen(false)}
                onConfirm={() => confirmDeleteWorkTask()}
                title={`Bekreft sletting`}
                text={`Er du sikker på at du vil slette ${activeWorkTask?.name}`}
                confirmText={"Ja"}
                cancelText={"Nei"}
            />
            <ConfirmDialog
                visible={confirmDeleteRiskModalOpen}
                onCancel={() => setConfirmDeleteRiskModalOpen(false)}
                onConfirm={() => confirmDeleteRisk()}
                title={`Bekreft sletting`}
                text={`Er du sikker på at du vil slette ${currentRisk?.name}`}
                confirmText={"Ja"}
                cancelText={"Nei"}
            />
        </VesselContextProvider>
    );
};

const PageButtons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 64px;
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;

  @media (min-width: 1200px) {
    grid-template-columns: 400px auto;
  }
`

const GridColumn = styled.div``

export const AssessmentPage = React.memo(AssessmentPageComponent);