
import styled from "styled-components";
export const ErrorMessageWrapper = styled.div<{ visible: boolean }>`
  justify-content: center;
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  transition: transform 250ms;
  transform: ${(props) =>
    props.visible ? "translateY(0px)" : "translateY(100px)"};
  opacity: ${(props) => (props.visible ? "100%" : "0%")};
  z-index: 1000;
`;