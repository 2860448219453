import {SafetyInstructionIcon} from "./types";

export const safetyInstructionTypes : SafetyInstructionIcon[] = [
    {
        "type": "Helmet",
        "title": "Hjelm",
        "large": "safety-icons/large/helmetActive.jpg",
        "thumbnail": "safety-icons/helmet_off.png"
    },
    {
        "type": "Mask",
        "title": "Friskluftsmaske",
        "large": "safety-icons/large/maskActive.jpg",
        "thumbnail": "safety-icons/mask_off.png"
    },
    {
        "type": "RespiratoryProtection",
        "title": "Ånderettsvern",
        "large": "safety-icons/large/respiratoryProtectionActive.jpg",
        "thumbnail": "safety-icons/respiratoryprotection_off.png"
    },
    {
        "type": "Gloves",
        "title": "Hansker",
        "large": "safety-icons/large/glovesActive.jpg",
        "thumbnail": "safety-icons/gloves_off.png"
    },
    {
        "type": "SafetyLine",
        "title": "Sikkerhetsline",
        "large": "safety-icons/large/safetyLineActive.jpg",
        "thumbnail": "safety-icons/safetyline_off.png"
    },
    {
        "type": "Goggles",
        "title": "Vernebriller",
        "large": "safety-icons/large/gogglesActive.jpg",
        "thumbnail": "safety-icons/goggles_off.png"
    },
    {
        "type": "Earmuffs",
        "title": "Hørselsvern",
        "large": "safety-icons/large/earmuffsActive.jpg",
        "thumbnail": "safety-icons/earmuffs_off.png"
    },
    {
        "type": "Floating",
        "title": "Flyteplagg",
        "large": "safety-icons/large/floatingActive.jpg",
        "thumbnail": "safety-icons/floating_off.png"
    },
    {
        "type": "FallProtection",
        "title": "Fallsikringsutstyr",
        "large": "safety-icons/large/fallProtectionActive.jpg",
        "thumbnail": "safety-icons/fallprotection_off.png"
    },
    {
        "type": "Visor",
        "title": "Visir",
        "large": "safety-icons/large/visorActive.jpg",
        "thumbnail": "safety-icons/visor_off.png"
    },
    {
        "type": "Boots",
        "title": "Vernesko",
        "large": "safety-icons/large/bootsActive.jpg",
        "thumbnail": "safety-icons/boots_off.png"
    },
];