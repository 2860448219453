import React from "react";
import styled from "styled-components";

import { Task, TaskProps } from "./Task";
import { WorkTask } from "../../types/types";
import { removeWorkTasks } from "../../data/riskManagementApi";
import { useVesselContext } from "../../context/vesselContext";

export interface TaskListProps {
  taskList?: Array<WorkTask>;
}

const TaskListComponent = ({ taskList }: TaskListProps) => {
  let listItem = taskList?.map((task: WorkTask, index) => {
    return (
      <li key={task.id}>
        <Task workTask={task}></Task>
      </li>
    );
  });

  return (
    <>
      <TaskListWrapper>{listItem}</TaskListWrapper>
    </>
  );
};

const TaskListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 64px;

  & > li + li {
    margin-top: 72px;
  }
`;

export const TaskList = React.memo(TaskListComponent);
