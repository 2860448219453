import {
    ExpandToggleButton,
    SdirButton,
    SdiDownload,
    SdirIconButton,
    SdiTrash,
    StatusLabel,
    styles,
} from "@sdir/sds";
import { useState } from "react";
import styled from "styled-components";
import { Risk, WorkTask } from "../../types/types";
import { PdfButton } from "../PdfButton/PdfButton";
import { RiskComponent } from "../Risk/Risk";
import { formatDateStringShort } from "../../helpers/dateHelpers";
import { useVesselContext } from "../../context/vesselContext";
import { CreateRiskPdf } from "data/riskManagementApi";

export interface RiskSummaryProps {
    risk: Risk;
    workTask: WorkTask;
}

const RiskSummaryComponent = ({ risk, workTask }: RiskSummaryProps) => {
    const [IsOpen, setIsOpen] = useState(false);

    const findNextDueDateInRisk = (risk: Risk) => {
        // Filters out undefined dates, and selects the closest date
        // Returns undefined if no measures have a duedate
        const measuresWithDueDate = risk.measures?.filter((x) => x.dueDate);

        const dates = measuresWithDueDate?.map((x) => x.dueDate);

        return dates?.reduce(
            (a?: Date, b?: Date) => (a || new Date(0) < (b || new Date(0)) ? a : b),
            undefined
        );
    };

    const nextDueDate = findNextDueDateInRisk(risk);

    const vesselContext = useVesselContext();

    const getRiskLabel = (probability: number, consequence: number, suffixText: string) => {
        const riskValue = probability + consequence;

        if (riskValue > 7) {
            return (<Tag className="error">
                {`Katastrofal risiko ${suffixText}`}
            </Tag>);
        }

        if (riskValue > 6) {
            return (<Tag className="error">
                {`Alvorlig risiko ${suffixText}`}
            </Tag>);
        }

        if (riskValue > 5) {
            return (<Tag className="error">
                {`Stor risiko ${suffixText}`}
            </Tag>);
        }

        if (riskValue > 4) {
            if (probability === 1 || consequence === 1) {
                return (<Tag className="warning">
                    {`Lav risiko ${suffixText}`}
                </Tag>);
            }

            return (<Tag className="warning">
                {`Moderat risiko ${suffixText}`}
            </Tag>);
        }

        if (riskValue > 3) {
            return (<Tag className="success">
                {`Liten risiko ${suffixText}`}
            </Tag>);
        }

        if (riskValue > 2) {
            return (<Tag className="success">
                {`Neglisjerbar risiko ${suffixText}`}
            </Tag>);
        }

        return (<Tag className="success">
            {`Ubetydelig risiko ${suffixText}`}
        </Tag>);
    }

    return (
        <RiskSummaryWrapper className={IsOpen ? "is-open" : ""}>
            <RiskSummaryHeader>
                <RiskSummaryHeaderColumn>
                    <RiskSummaryTitle>{risk.name}</RiskSummaryTitle>
                    <RiskSummaryHeaderMeta>
                        <li>{risk.measures?.length} tiltak</li>
                        {nextDueDate && (
                            <li>Neste frist: {formatDateStringShort(nextDueDate)}</li>
                        )}
                        {!nextDueDate && <li>Neste frist: Ingen</li>}
                    </RiskSummaryHeaderMeta>
                </RiskSummaryHeaderColumn>
                <RiskSummaryHeaderColumn>
                    <RiskSummaryStatus title="Risiko før/etter tiltak">
                        <div className="before-after">
                            {risk.probabilityBefore && (
                                getRiskLabel(risk.probabilityBefore, risk.consequenceBefore, "før tiltak")
                            )}{" "}
                            →
                            {risk.probabilityAfter && (
                                getRiskLabel(risk.probabilityAfter, risk.consequenceAfter, "etter tiltak")
                            )}
                        </div>
                    </RiskSummaryStatus>
                    <ExpandToggleButton
                        isToggled={IsOpen}
                        text=""
                        onClick={() => setIsOpen(!IsOpen)}
                    />
                </RiskSummaryHeaderColumn>
            </RiskSummaryHeader>
            <RiskSummaryBody>
                <RiskSummaryButtons>
                    <DownloadButtonWrapper>
                        <PdfButton onClick={async (e) => {
                            e.preventDefault();
                            const pdf = await CreateRiskPdf(risk.id, vesselContext.currentVesselId);
                            var file = window.URL.createObjectURL(pdf);
                            window.open(file, "_blank");
                        }}>
                            Last ned
                            <SdiDownload height={"18px"} style={{ marginLeft: "8px" }} />
                        </PdfButton>
                    </DownloadButtonWrapper>
                    <SdirButton
                        onClick={() => {
                            vesselContext.openEditRiskModal(risk, workTask);
                        }}
                    >
                        Rediger
                    </SdirButton>
                    <SdirIconButton
                        icon={SdiTrash}
                        onClick={() => {
                            vesselContext.openConfirmDeleteModalForRisk?.(risk);
                        }}
                    ></SdirIconButton>
                </RiskSummaryButtons>
                <RiskComponent risk={risk} allowCompletion={true} />
            </RiskSummaryBody>
        </RiskSummaryWrapper>
    );
};

const RiskSummaryWrapper = styled.article`
  background-color: ${styles.colors.background.secondary};
  border-radius: ${styles.card.borderRadius};
  box-shadow: ${styles.card.boxShadow};
  margin-bottom: 20px;
`;

const RiskSummaryHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }

  .is-open & {
    border-bottom: 1px solid ${styles.colors.primary8};
  }
`;

const RiskSummaryHeaderColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  &:first-of-type {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

const RiskSummaryHeaderMeta = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  font-size: ${styles.font.size.md};
  li + li {
    &:before {
      margin-right: 12px;
      content: "•";
    }
  }
`;

const RiskSummaryTitle = styled.h3`
  margin: 0;
`;

const RiskSummaryStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .before-after {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
  }
`;

const RiskSummaryBody = styled.div`
  padding: 32px 24px;
  display: none;
  .is-open & {
    display: block;
  }
`;

const RiskSummaryButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

const DownloadButtonWrapper = styled.div`
  margin-right: auto;
`;

const Tag = styled.span`
    display: inline-flex;
    border-radius: 4px;
    font-family: ${styles.fonts.stfMedium};
    padding: .25em .75em;
    font-size: 1.75rem;
    &.warning {
        background-color: ${styles.colors.alert.warningLight};
        color: ${styles.colors.alert.warningDark};
    }
    
    &.error {
        background-color: ${styles.colors.alert.errorLight};
        color: ${styles.colors.alert.errorDark};
    }
    
    
    &.success {
        background-color: ${styles.colors.alert.successLight};
        color: ${styles.colors.alert.successDark};    
    }
`

export const RiskSummary = RiskSummaryComponent;
