import React, { useEffect, useRef, useState } from "react";
import { MatrixWrapper } from "./style";

export interface MatrixProps {
  beforeConsequence: number
  afterConsequence: number
  beforeProbability: number
  afterProbability: number
  children?: React.ReactNode
}

const MatrixComponent = ({
  children,
  beforeConsequence,
  beforeProbability,
  afterConsequence,
  afterProbability,
}: MatrixProps) => {
  const [beforePos, setBeforePos] = useState(0)
  const [afterPos, setAfterPos] = useState(0)

  const getPosition = (consequence: number, probability: number) => {
    return (4 - consequence) * 4 + probability - 1;
  };

  useEffect(() => {
    setBeforePos(getPosition(beforeConsequence, beforeProbability));
    setAfterPos(getPosition(afterConsequence, afterProbability));
  }, [
    beforeConsequence,
    beforeProbability,
    afterConsequence,
    afterProbability,
  ]);

  const getSelectedClass = (index: number) => {
    if (index === afterPos && index === beforePos) return "selected-unchanged";
    if (index === afterPos) return "selected-solid";
    if (index === beforePos) return "selected-dashed";
    return "";
  };

  const matrixIndex = 0;

  return (
    <MatrixWrapper>
      <div className="matrix-grid">
        <div>
          <span>Konsekvens</span>
        </div>
        <div className={`matrix-grid-inner`}>
          <div
            className={`matrix-grid-inner-cell medium ${getSelectedClass(
              matrixIndex + 0
            )}`}
          >
            Lav
          </div>
          <div
            className={`matrix-grid-inner-cell high ${getSelectedClass(
              matrixIndex + 1
            )}`}
          >
            Stor
          </div>
          <div
            className={`matrix-grid-inner-cell high ${getSelectedClass(
              matrixIndex + 2
            )}`}
          >
            Alvorlig
          </div>
          <div
            className={`matrix-grid-inner-cell high ${getSelectedClass(
              matrixIndex + 3
            )}`}
          >
            Katastrofal
          </div>
          <div
            className={`matrix-grid-inner-cell low ${getSelectedClass(
              matrixIndex + 4
            )}`}
          >
            Liten
          </div>
          <div
            className={`matrix-grid-inner-cell medium ${getSelectedClass(
              matrixIndex + 5
            )}`}
          >
            Moderat
          </div>
          <div
            className={`matrix-grid-inner-cell high ${getSelectedClass(
              matrixIndex + 6
            )}`}
          >
            Stor
          </div>
          <div
            className={`matrix-grid-inner-cell high ${getSelectedClass(
              matrixIndex + 7
            )}`}
          >
            Alvorlig
          </div>
          <div
            className={`matrix-grid-inner-cell low ${getSelectedClass(
              matrixIndex + 8
            )}`}
          >
            Neglisjerbar
          </div>
          <div
            className={`matrix-grid-inner-cell low ${getSelectedClass(
              matrixIndex + 9
            )}`}
          >
            Liten
          </div>
          <div
            className={`matrix-grid-inner-cell medium ${getSelectedClass(
              matrixIndex + 10
            )}`}
          >
            Moderat
          </div>
          <div
            className={`matrix-grid-inner-cell high ${getSelectedClass(
              matrixIndex + 11
            )}`}
          >
            Stor
          </div>
          <div
            className={`matrix-grid-inner-cell low ${getSelectedClass(
              matrixIndex + 12
            )}`}
          >
            Ubetydelig
          </div>
          <div
            className={`matrix-grid-inner-cell low ${getSelectedClass(
              matrixIndex + 13
            )}`}
          >
            Neglisjerbar
          </div>
          <div
            className={`matrix-grid-inner-cell low ${getSelectedClass(
              matrixIndex + 14
            )}`}
          >
            Liten
          </div>
          <div
            className={`matrix-grid-inner-cell medium ${getSelectedClass(
              matrixIndex + 15
            )}`}
          >
            Lav
          </div>
        </div>
        <div>
          <span>Sannsynlighet</span>
        </div>
      </div>
      <div className="matrix-legend-labels">
        <span className="matrix-legend-label dashed">Risiko før tiltak</span>
        <span className="matrix-legend-label solid">Risiko etter tiltak</span>
      </div>
      {children && <div className="matrix-description">{children}</div>}
    </MatrixWrapper>
  )
}

export const Matrix = React.memo(MatrixComponent)
