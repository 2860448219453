import { ReactNode } from "react";
import { SdiCircleArrow, styles } from "@sdir/sds";
import styled from "styled-components";

export interface ListAccordionProps {
  title: string;
  children?: ReactNode;
  open?: boolean;
}

const ListAccordionComponent = ({ title, children, open = false }: ListAccordionProps) => {
  return (
    <Details open={open} onKeyDown={e => {
        const htmlInputElement = (e.target as HTMLInputElement);
        if(e.key === "Enter" && htmlInputElement?.type === "checkbox") {
            e.preventDefault();
        }
    }}>
      <Summary>
        <SdiCircleArrow direction="down" />
        <span>{title}</span>
      </Summary>
      <Content>{children}</Content>
    </Details>
  );
};

const Details = styled.details`
  &[open] {
      Summary svg {
        transform: rotate(180deg);
      }

      Content {
        display: block;
      }
  } 
`;

const Summary = styled.summary`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-size: 18px;

  svg {
    transition: .15s ease;
    flex-shrink: 0;
    path {
        fill: ${styles.colors.primary};
    }
  }
`;

const Content = styled.div`
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3.25rem;
    margin-left: 2rem;
    border-left: 2px solid ${styles.colors.primary10};
`;

export const ListAccordion = ListAccordionComponent;
