import {TextField} from "@sdir/sds";
import {InfoBox, StepContentWrapper} from "../style";
import {useFormContext} from "../../../context/formContext";
import {useEffect, useState} from "react";
import {Step} from "../../SdirWizard";

export const DescribeRiskStep = (props: Step) => {
    const {title } = props;

    const formContext = useFormContext();
    
    const [name, setName] = useState(formContext.getValue("name") ?? "");
    
    useEffect(() => {
        formContext.setValue("name", name);
    }, [name]);
    
    return (
        <StepContentWrapper>
            <h3>{title}</h3>
            <InfoBox>Hvilken fare kan oppstå ved utførelse av denne arbeidsoppgaven? Målet er å identifisere en fare som
                kan oppstå slik at du kan opprette tiltak som kan forhindre at dette skjer like ofte eller fører til
                mindre skade.</InfoBox>
            <br/>
            <TextField id={"1"} label={"Hva slags fare kan oppstå?"} name={"Name"}
                       helptext="F.eks. 'Falle på sjøen' eller 'Komme i klem'. Vær så spesifikk som mulig."
                       value={name}
                       onChange={(e) => setName(e.target.value)}></TextField>
        </StepContentWrapper>
    );
}