import { SdirAuthProvider, SdirAuthProviderProps } from "@sdir/auth/lib/providers/SdirAuthProvider";
import {ReactNode} from "react";

interface ProvidersProps {
    children?: ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({children}: ProvidersProps) => {
    const authSettings: SdirAuthProviderProps = {
        authenticationSettings: {
            client_id: process.env.REACT_APP_CLIENT_ID as string,
            client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
            scope: process.env.REACT_APP_SCOPE as string,
        },
    };

    return (
        <SdirAuthProvider {...authSettings}>
            {children}
        </SdirAuthProvider>
    );
};

export default Providers;
