export interface Measure {
    id?: number,
    title: string,
    completed: boolean,
    description: string,
    dueDate?: Date,
    responsible: string,
    responsibleEmail: string,
    safetyInstruction?: SafetyInstruction
}

export interface Risk {
    id?: number,
    name: string,
    probabilityBefore: number,
    consequenceBefore: number,
    probabilityAfter: number,
    consequenceAfter: number,
    measures?: Measure[]
}

export interface WorkTask {
    name: string,
    id: number,
    risks: Risk[],
    assessmentCategoryIds: number[],
    isDefault: boolean
}

export interface RiskAssessment {
    workProcesses: WorkProcess[],
    vessel: Vessel
}

export interface WorkProcess {
    id: number,
    name: string,
    workTasks: WorkTask[],
    vessel: Vessel 
}

export interface Vessel {
    id?: number,
    name?: string,
    callSign?: string,
    buildYear?: number,
    weight?: number,
    width?: number,
    length?: number,
    depth?: number,
    numPersonnel?: number,
    contactName?: string,
    contactEmail?: string,
    contactPhone?: string,
    dateCreated?: Date,
    dateUpdated?: Date,
}

export interface SafetyInstruction {
    headerText: string;
    selectedIcons: string[];
    id?: number;
}
export interface SafetyInstructionIcon {
    title?: string,
    large?: string,
    thumbnail?: string,
    type?: string
}

export const defaultEmptyVessel = {
    id: undefined,
    name: "",
    callSign: "",
    buildYear: undefined,
    weight: undefined,
    width: undefined,
    length: undefined,
    depth: undefined,
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    numPersonnel: undefined
} as Vessel;