import React, { useEffect } from "react";
import { Header } from "ui/Header";
import { InfoWrapper } from "./HomePage";
import { TextLink } from "@sdir/sds";
import { useNavigate } from "react-router";
import { useCurrentUser } from "@sdir/auth/lib/hooks";

const LandingPageComponent = () => {

    const navigate = useNavigate();
    const handleLoginClick = () => navigate('/app');
    const currentUser = useCurrentUser();

    useEffect(() => {
        if (currentUser && !currentUser.expired) {
            navigate("/app");
        }
    })

    return (
        <>
            <Header title="Velkommen til SDIRs risikovurderingsverktøy" />
            <InfoWrapper>
                <div>
                    <h2>Du er ikke logget inn</h2>
                    <p>For å opprette eller redigere risikovurderinger for dine fartøy er du nødt til å logge inn</p>
                </div>
                <TextLink text={"Logg inn"} strong={true} onClick={handleLoginClick} />
            </InfoWrapper>
        </>
    )
}

export const LandingPage = React.memo(LandingPageComponent);