import {SdiArrow, SdirButton, SdirDatePicker, TextField} from "@sdir/sds";
import {CollapsableFields, DatePickerContainer, InfoBox} from "./style";
import {SymbolPicker} from "../SymbolPicker";
import {useEffect, useState} from "react";
import {Measure, SafetyInstructionIcon} from "../../types/types";
import Textarea from "../TextArea/TextArea";
import {getSafetyIcons} from "../../data/riskManagementApi";
import {safetyInstructionTypes} from "../../types/SafetyInstructionIcons";

interface MeasureFormProps {
    id?: number;
    title: string;
    completed: boolean;
    description: string;
    dueDate?: Date;
    responsible: string;
    responsibleEmail: string;
    safetyInstructionText?: string;
    selectedSymbols?: string[];
    index: number;
    removeMeasure: (index: number) => void;
    onChange: (measure: Measure) => void;
}

export const MeasureForm = ({
                                id,
                                title,
                                completed,
                                description,
                                dueDate,
                                responsible,
                                responsibleEmail,
                                safetyInstructionText,
                                selectedSymbols,
                                removeMeasure,
                                index,
                                onChange,
                            }: MeasureFormProps) => {
    const [measureTitle, setTitle] = useState(title);
    const [measureDescription, setMeasureDescription] = useState(description);
    const [measureComplete, setComplete] = useState(completed);
    const [measureResponsible, setResponsible] = useState(responsible);
    const [measureResponsibleEmail, setResponsibleEmail] =
        useState(responsibleEmail);
    const [measureDueDate, setDueDate] = useState<Date | undefined>(dueDate);
    const [instructionText, setInstructionText] = useState<string>(
        safetyInstructionText ?? ""
    );
    const [safetyInstructionSelectedSymbols, setSelectedSymbols] = useState<string[]>(selectedSymbols ?? []);
    
    useEffect(() => {
        onChange({
            id,
            title: measureTitle,
            description: measureDescription,
            completed: measureComplete,
            responsible: measureResponsible,
            responsibleEmail: measureResponsibleEmail,
            dueDate: measureDueDate,
            safetyInstruction: {
                headerText: instructionText,
                selectedIcons: safetyInstructionSelectedSymbols,
            },
        });
    }, [
        measureTitle,
        measureDescription,
        measureComplete,
        instructionText,
        measureResponsible,
        measureResponsibleEmail,
        measureDueDate,
        safetyInstructionSelectedSymbols,
    ]);

    return (
        <CollapsableFields open key={"measureform" + measureTitle + index}>
            <summary>
                <SdiArrow height="8px"/>
                <span>Tiltak {index + 1}</span>
            </summary>
            <div>
                <Textarea
                    placeholder="Beskrivelse av tiltak"
                    name={"measure" + index}
                    onChange={(e) => setMeasureDescription(e.target.value)}
                    contentValue={measureDescription}
                ></Textarea>
                <TextField
                    label="Ansvarlig for at tiltaket utføres eller følges opp"
                    name={"responsible" + index}
                    id={""}
                    onChange={(e) => setResponsible(e.target.value)}
                    value={measureResponsible}
                />
                <TextField
                    label="Ansvarligs e-postadresse"
                    name={"responsibleEmail" + index}
                    id={""}
                    onChange={(e) => setResponsibleEmail(e.target.value)}
                    value={measureResponsibleEmail}
                />
                <DatePickerContainer>
                    <SdirDatePicker
                        value={measureDueDate}
                        onChange={(date) => setDueDate(date as Date)}
                        placeholder="Velg en dato (valgfritt)"
                        locale="no"
                        name={"dueDate" + index}
                    />
                </DatePickerContainer>
                <InfoBox>
                    Hvis du vil kan du her lage en sikkerhetsinstruks. Beskriv instruksen
                    og velg de symbolene som skal utgjøre plakaten. Denne kan skrives ut i
                    ettertid og henges opp der hvor arbeidsoppgaven skal utføres.
                </InfoBox>
                <Textarea
                    required={false}
                    placeholder="Instruks (valgfritt)"
                    name={"instruction" + index}
                    onChange={(e) => setInstructionText(e.target.value)}
                    contentValue={instructionText}
                ></Textarea>
                <SymbolPicker
                    title="Velg symbol(er) (valgfritt)"
                    symbols={safetyInstructionTypes.map(x => {
                        return {
                            image: "/" + (x.thumbnail ?? ""), 
                            title: x.title ?? "", 
                            id: x.type?.toString() ?? ""
                        }
                    })}
                    initialSelectedSymbols={safetyInstructionSelectedSymbols}
                    updateSelectedSymbols={setSelectedSymbols}
                ></SymbolPicker>
                {index > 0 && (
                    <div>
                        <SdirButton
                            htmlType="button"
                            type="danger"
                            onClick={(e) => {
                                e.preventDefault();
                                removeMeasure(index);
                            }}
                        >
                            Fjern tiltak
                        </SdirButton>
                    </div>
                )}
            </div>
        </CollapsableFields>
    );
};
