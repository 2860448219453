import {httpClient} from "../httpsclient";

export const get = async (url: string) => {
    const response = await httpClient.client.get(url);
    return response.data;
};

export const post = async (url: string, item: any) => {
    const response = await httpClient.client.post(url, item);

    if (response.status !== 200) {
        throw "Noe gikk galt";
    }

    return response.data;
};

export const updateItem = async (url: string, item: any) => {
    try {
        const response = await httpClient.client.put(url, item);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteItem = async (url: string, item: any) => {
    try {
        const response = await httpClient.client.delete(url,{ data: item });
        return response.data;
    } catch (e) {
        console.log(e);
    }
};

export const blob = async (url: string) => {
    const response = await httpClient.client.get(url, {responseType: 'blob'});
    return response.data;
};
