import {Measure, Risk, RiskAssessment, SafetyInstructionIcon, Vessel, WorkProcess} from "../types/types";
import {blob, deleteItem, get, post, updateItem} from "../api/request";

export const apiRoute = process.env.REACT_APP_API_URL + "/los-riskmanagement-internal";

export const getRiskAssessment = async (vesselId: number): Promise<RiskAssessment> => {
    const riskAssessment = await get(`${apiRoute}/Vessel/GetRiskAssessment?vesselId=${vesselId}`);
    return riskAssessment;
}

export const getAssessmentCategories = async (): Promise<[]> => {
    const assessmentCategories = await get(`${apiRoute}/RiskManagement/GetAssessmentCategories`);
    return assessmentCategories;
}

export const getWorkTaskChoiceList = async (vesselId: number): Promise<WorkProcess[]> => {
    const workTaskChoiceList = await get(`${apiRoute}/Vessel/GetChoices?vesselId=${vesselId}`);
    return workTaskChoiceList;
}

export const removeWorkTasks = async (workTaskIds: number[], vesselId: number) => {
    await deleteItem(`${apiRoute}/Vessel/RemoveWorkTasksFromVessel`,
        {
            workTaskIds,
            vesselId
        });
}

export const deleteMeasure = async (measureId: number, vesselId: number) => {
    const measureDeletion = await deleteItem(`${apiRoute}/Measure/DeleteMeasure`,
        {
            measureId,
            vesselId
        });

    return measureDeletion;
}

export const addRiskToWorkTask = async (risk: Risk, workTaskId: number, vesselId: number) => {
    const addedRisk = await post(`${apiRoute}/Risk/AddRiskToWorkTask`,
        {
            risk,
            workTaskId,
            vesselId
        });

    return addedRisk;
}

export const updateRisk = async (risk: Risk, vesselId: number) => {
    await post(`${apiRoute}/Risk/UpdateRisk`,
        {
            risk,
            vesselId
        });
}

export const deleteRisk = async (riskId: number, vesselId: number) => {
    await deleteItem(`${apiRoute}/Risk/DeleteRisk`,
        {
            riskId,
            vesselId
        });
}

export const addMeasure = async (measure: Measure, toRiskId: number, vesselId: number) => {
    const addedMeasure = await post(`${apiRoute}/Measure/AddMeasureToRisk`,
        {
            measure,
            riskId: toRiskId,
            vesselId
        });

    return addedMeasure;
}

export const markMeasureAsComplete = async (measureId: number, vesselId: number) => {
    const addedMeasure = await post(`${apiRoute}/Measure/MarkMeasureAsComplete`, {
        measureId,
        vesselId
    });

    return addedMeasure;
}

export const addWorkTasks = async (workTaskIds: number[], vesselId: number) => {
    const addedWorkTask = await post(`${apiRoute}/Vessel/AddWorkTaskToVessel`,
        {
            workTaskIds,
            vesselId
        });

    return addedWorkTask;
}

export const getVesselList = async () => {
    const result = await get(`${apiRoute}/Vessel/GetVessels`);

    return result;
};

export const postVessel = async (item: Vessel) => {
    try {
        const postedVessel = await post(`${apiRoute}/Vessel/AddVessel`, item);
    } catch (err) {
        throw "Noe gikk galt ved oppdatering av skip";
    }
};

export const updateVessel = async (item: Vessel) => {
    const updateResult = await updateItem(`${apiRoute}/Vessel/UpdateVessel`, item)

    return updateResult;
};

export const deleteVessel = async (id?: number) => {
    if (!id) {
        throw "ID is undefined";
    }
    await deleteItem(`${apiRoute}/Vessel/DeleteVessel?vesselId=${id}`, {});
};

export const addCustomWorkTask = async (workTaskName: string, workProcessId: number, vesselId: number) => {
    const addedWorkTask = await post(`${apiRoute}/Vessel/AddCustomTaskToVessel`,
        {
            workTaskName,
            workProcessId,
            vesselId
        });

    return addedWorkTask;
}

export const getSafetyIcons = async (): Promise<SafetyInstructionIcon[]> => {
    const safetyIcons = await get(`${apiRoute}/Risk/GetSafetyInstructionIcons`);
    return safetyIcons;
}

export const CreateSafetyInstructionPdf = async (safetyInstructionId?: number, currentVesselId? : number): Promise<File> => {
    const pdf = await blob(`${apiRoute}/Pdf/CreateSafetyInstructionPdf?safetyInstructionId=${safetyInstructionId}&vesselId=${currentVesselId}`);
    
    return pdf;
}

export const CreateRiskPdf = async (riskId?: number, currentVesselId? : number): Promise<File> => {
    const pdf = await blob(`${apiRoute}/Pdf/CreateRiskPdf?riskId=${riskId}&vesselId=${currentVesselId}`);

    return pdf;
}

export const CreateVesselPdf = async (currentVesselId? : number): Promise<File> => {
    const pdf = await blob(`${apiRoute}/Pdf/CreateVesselPdf?vesselId=${currentVesselId}`);

    return pdf;
}

export const requestOwnershipTransfer = async (email: string) => {
    const request = await post(`${apiRoute}/RiskManagement/RequestOwnership`,
        {
            email
        });

    return request;
}

export const transferOwnerships = async (email: string, confirmationCode: string) => {
    const request = await post(`${apiRoute}/RiskManagement/TransferOwnerships`,
        {
            oldOwnerKey: email,
            confirmationCode
        });

    return request;
}