import {SdirButton} from "@sdir/sds";
import React, {useEffect, useState} from "react";
import {InfoBox, StepContentWrapper} from "../style";
import {useFormContext} from "../../../context/formContext";
import {MeasureForm} from "../MeasureForm";
import {Measure} from "../../../types/types";
import {Step} from "../../SdirWizard";

const emptyMeasure = {
    id: undefined,
    title: "",
    completed: false,
    description: "",
    dueDate: undefined,
    responsible: "",
    responsibleEmail: "",
    safetyInstruction: undefined
};

export const AddMeasuresStep = ({title, risk}: Step) => {
    const formContext = useFormContext();

    const [measures, setMeasures] = useState<Measure[]>(
        (formContext.getValue("measures") as Measure[])?.map((m) => {
            return {...m, dueDate: new Date(m.dueDate ?? new Date())};
        }) ?? [emptyMeasure]
    );

    const addMeasure = () => {
        setMeasures([...measures, emptyMeasure]);
    };

    const removeMeasure = (atIndex: number) => {
        const newMeasureList = [...measures];
        newMeasureList.splice(atIndex, 1);

        setMeasures(newMeasureList);
    };

    useEffect(() => {
        formContext.setValue("measures", measures);
    }, [measures]);

    return (
        <StepContentWrapper>
            {risk?.name && <p><strong>Fare:</strong> "{risk?.name}"</p>}
            <h3>{title}</h3>
            <InfoBox>
                Opprett tiltak som skal bidra til å redusere risikoen. Registrer minimum
                ett tiltak.
            </InfoBox>
            {measures.map((item, index) => (
                <MeasureForm
                    key={index}
                    {...item}
                    selectedSymbols={item.safetyInstruction?.selectedIcons}
                    safetyInstructionText={item.safetyInstruction?.headerText}
                    index={index}
                    removeMeasure={removeMeasure}
                    onChange={(measure: Measure) => {
                        {
                            const measuresCopy = [...measures];
                            measuresCopy[index] = measure;
                            setMeasures(measuresCopy);
                        }
                    }}
                />
            ))}
            <SdirButton
                htmlType="button"
                text="Nytt tiltak +"
                onClick={(e) => {
                    e.preventDefault();
                    addMeasure();
                }}
            ></SdirButton>
        </StepContentWrapper>
    );
};
