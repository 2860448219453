import { RiskManagementRoutes } from "routes/RiskManagementRoutes";

function App() {
    return (
        <div className="App">
            <RiskManagementRoutes/>
        </div>
    );
}

export default App;
