import { SdirButton } from "@sdir/sds";
import React, { FormEvent } from "react";
import styled from "styled-components";
import { SdirFormModal } from "../SdirModal";

interface ConfirmDialogProps {
  visible: boolean;
  onConfirm: Function;
  onCancel: Function;
  title: string;
  text: string;
  confirmText: string;
  cancelText: string;
  confirmButtonType?: "default" | "danger" | "primary" | "ghost";
}

export const ConfirmDialog = ({
  visible,
  onConfirm,
  onCancel,
  title,
  text,
  confirmText,
  cancelText,
  confirmButtonType
}: ConfirmDialogProps) => (
  <SdirFormModal
    visible={visible}
    title={title}
    onCancel={() => onCancel()}
    onSubmit={(e) => {
      e.preventDefault();
      onConfirm();
    }}
    okText={confirmText}
    cancelText={cancelText}
    width="600px"
    okButtonType={confirmButtonType}
  >
    <FormContentWrapper>
      <DialogMessage>{text}</DialogMessage>
    </FormContentWrapper>
  </SdirFormModal>
);

const FormContentWrapper = styled.div`
  padding: 4rem 3rem;
`;

const DialogMessage = styled.div`
    margin: 0;
    max-width: 60ch;
`
