import {InfoBox, StepContentWrapper} from "../style";
import {useFormContext} from "../../../context/formContext";
import React, {useEffect, useState} from "react";
import {Step} from "../../SdirWizard";
import {RadioButtonGroup} from "../../RadioButtonGroup/RadioButtonGroup";

const probabilityAfterOptions = [
    {name: "Svært usannsynlig — inntreffer én gang pr. 50 år eller sjeldnere", value: "1"},
    {name: "Usannsynlig — inntreffer én gang pr. 10 år eller sjeldnere", value: "2"},
    {name: "Mulig — inntreffer årlig eller sjeldnere", value: "3"},
    {name: "Sannsynlig — inntreffer flere ganger årlig", value: "4"}];

const consequenceAfterOptions = [
    {name: "Ufarlig — ubetydelig skade på menneske, miljø eller materiell", value: "1"},
    {name: "Farlig — Småskader med kort fravær, moderate miljøskader, mindre lokal skade på fartøy/meteriell", value: "2"},
    {name: "Kritisk — Alvorlig personskade med langt fravær eller varige mén, større lokale skader på miljø, alvorlige skader på fartøy/materiell", value: "3"},
    {name: "Katastrofal — Kan resultere i død for én eller flere personer, meget alvorlige skader på miljø, tap av, eller kritisk skade på fartøy/materiell", value: "4"}];

export const AssessAfterMeasuresStep = ({title, risk}: Step) => {
    const formContext = useFormContext();

    const [probabilityAfter, setProbabilityAfter] = useState(formContext.getValue("probabilityAfter")?.toString() ?? "1");
    const [consequenceAfter, setConsequenceAfter] = useState(formContext.getValue("consequenceAfter")?.toString() ?? "1");

    useEffect(() => {
        formContext.setValue("probabilityAfter", Number(probabilityAfter));
        formContext.setValue("consequenceAfter", Number(consequenceAfter));
    }, [probabilityAfter, consequenceAfter])

    return (
        <StepContentWrapper>
            {risk?.name && <p><strong>Fare:</strong> "{risk?.name}"</p>}
            <h3>{title}</h3>
            <InfoBox type="warning">
                Du skal nå gjøre en ny vurdering av faren dersom <strong>tiltakene du registrerte i forrige steg blir
                iverksatt</strong>. Har konsekvensen eller sannsynligheten blitt mindre? Eller er den uforandret?
            </InfoBox>
            <h4>Hvor sannsynlig er det at dette oppstår?</h4>
            <RadioButtonGroup name={"probabilityAfter"} options={probabilityAfterOptions} value={probabilityAfter}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProbabilityAfter(e.currentTarget.value)}/>
            <br></br>
            <h4>Hva blir konsekvensen dersom dette oppstår?</h4>
            <RadioButtonGroup
                options={consequenceAfterOptions}
                name={"consequenceAfter"} value={consequenceAfter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConsequenceAfter(e.currentTarget.value)}
            />
        </StepContentWrapper>
    );
};