import React, {useEffect, useState} from "react";
import {styles} from "@sdir/sds";
import styled from "styled-components";

export interface SymbolProps {
    image: string;
    title: string;
    id: string;
}

export interface SymbolPickerProps {
    title: string;
    symbols: SymbolProps[];
    updateSelectedSymbols?: (symbols: string[]) => void;
    initialSelectedSymbols?: string[];
}

const SymbolPickerComponent = ({title, symbols, updateSelectedSymbols, initialSelectedSymbols}: SymbolPickerProps) => {
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>(initialSelectedSymbols ?? []);

    useEffect(() => {
        updateSelectedSymbols && updateSelectedSymbols(selectedSymbols);
    }, [selectedSymbols]);

    return (
        <>
            <Symbols>
                <legend>{title}</legend>
                {symbols && symbols.map((symbol, index) => {
                    return (
                        <Label key={index}>
                            <input
                                onChange={(e) => {
                                    const selectedSymbolsCopy = [...selectedSymbols];

                                    if (e.target.checked) {
                                        selectedSymbolsCopy.push(symbol.id);
                                    } else {
                                        selectedSymbolsCopy.splice(
                                            selectedSymbols.indexOf(symbol.id),
                                            1
                                        );
                                    }
                                    
                                    setSelectedSymbols(selectedSymbolsCopy);
                                }} type="checkbox" name="symbolPicker"
                                checked={selectedSymbols.includes(symbol.id)}/>
                            <Symbol>
                                {<img src={symbol.image} alt=""/>}
                                <span>{symbol.title}</span>
                            </Symbol>
                        </Label>
                    );
                })}
            </Symbols>
        </>
    );
};

export const SymbolPicker = SymbolPickerComponent;

const Symbols = styled.fieldset`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: stretch;
  gap: 1rem;
  padding: 0;
  border: 0;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  legend {
    margin-bottom: 1.5rem;
  }
`;
const Label = styled.label`
  input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    &:checked + div {
      background-color: ${styles.colors.primary10};
      border-color: ${styles.colors.primary};
      &:after {
        content: "✓";
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${styles.colors.primary};
        color: ${styles.colors.primary};
        background-color: #fff;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 16px;
      }
    }
  }
  &:focus-within {
    & > div {
      border-color: ${styles.colors.primary};
    }
  }
`;

const Symbol = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${styles.colors.primary8};
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  word-break: break-all;
  padding: 1rem;
  cursor: pointer;
  background-color: #fff;
  position: relative;
  height: 100%;
  font-size: 14px;

  &:hover {
    border-color: ${styles.colors.primary};
  }

  img {
    display: block;
    max-width: 100%;
  }
`;
