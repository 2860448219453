import { SdirButton, SdirRadioButtonGroup, styles, Textarea, TextField } from "@sdir/sds";
import { useState } from "react";
import styled from "styled-components";
import { SdirWizard } from "./SdirWizard";
import { SymbolPicker } from "ui/SymbolPicker";
import {DescribeRiskStep} from "../AddRiskWizard/Steps/DescribeRiskStep";
import {AssessBeforeMeasuresStep} from "../AddRiskWizard/Steps/AssessBeforeMeasuresStep";
import {AddMeasuresStep} from "../AddRiskWizard/Steps/AddMeasuresStep";
import {AssessAfterMeasuresStep} from "../AddRiskWizard/Steps/AssessAfterMeasuresStep";
import {SummaryStep} from "../AddRiskWizard/Steps/SummaryStep";

export default {
  title: "Legg til fare-wizard",
  component: SdirWizard,
};

const symbols = [{ image: "/safety-icons/helmet_off.png", title: "Hjelm" },
    { image: "/safety-icons/mask_off.png", title: "Gassmaske" },
    {
        image: "/safety-icons/respiratoryprotection_off.png",
        title: "Maske",
    },
    {
        image: "/safety-icons/gloves_off.png",
        title: "Hansker",
    },
    {
        image: "/safety-icons/fallprotection_off.png",
        title: "Fallsikringsutstyr",
    },
    {
        image: "/safety-icons/goggles_off.png",
        title: "Vernebriller",
    }
];

const Step1 = () => {
  return (
    <>
      <h3>Registrer fare</h3>
      <TextField
        id={"1"}
        helptext="Hvilken fare kan oppstå ved utførelse av denne arbeidsoppgaven?"
        label={"Fare"}
      ></TextField>
    </>
  );
};

const Step2 = () => {
  return (
    <>
      <h3>Vurder faren</h3>
      <p>
        Vurder hvor stor sannsynlighet det er for at faren oppstår — og hvor stor konsekvens det vil
        ha dersom ingen tiltak er iverkstatt enda
      </p>
      <h4>Hvor sannsynlig er det at dette oppstår?</h4>
      <SdirRadioButtonGroup
        options={[
          { label: "Svært usannsynlig — inntreffer én gang pr. 50 år eller sjeldnere", value: "1" },
          { label: "Usannsynlig — inntreffer én gang pr. 10 år eller sjeldnere", value: "2" },
          { label: "Mulig — inntreffer årlig eller sjeldnere", value: "3" },
          { label: "Sannsynlig — inntreffer flere ganger årlig", value: "4" },
        ]}
        value={"1"}
        onChange={() => console.log("value changed")}
      />
      <br></br>
      <h4>Hva blir konsekvensen dersom dette oppstår?</h4>
      <SdirRadioButtonGroup
        options={[
          { label: "Ufarlig — ubetydelig skade på menneske, miljø eller materiell", value: "1" },
          {
            label:
              "Farlig — Småskader med kort fravær, moderate miljøskader, mindre lokal skade på fartøy/meteriell",
            value: "2",
          },
          {
            label:
              "Kritisk — Alvorlig personskade med langt fravær eller varige mén, større lokale skader på miljø, alvorlige skader på fartøy/materiell",
            value: "3",
          },
          {
            label:
              "Katastrofal — Kan resultere i død for én eller flere personer, meget alvorlige skader på miljø, tap av, eller kritisk skade på fartøy/materiell",
            value: "4",
          },
        ]}
        value={"1"}
        onChange={() => console.log("value changed")}
      />
    </>
  );
};
const Step3 = () => {
  return (
    <>
      <h3>Opprett tiltak</h3>
      <h4>Tiltak</h4>
      <p>Opprett tiltak som skal bidra til å redusere risikoen. Registrer minimum ett tiltak.</p>
      <Fieldset>
        <legend>Tiltak 1</legend>
        <Textarea placeholder="Beskrivelse av tiltak" name="tiltak"></Textarea>
        <TextField
          label="Ansvarlig for at tiltaket utføres eller følges opp"
          name="ansvarlig"
          id={""}
        />
        <TextField label="Ansvarligs e-postadresse" name="ansvarlig-epost" id={""} />
        <TextField label="Tiltaket skal utføres innen (valgfritt)" name="dato" id={""} />
        <p>Lag sikkerhetsinstrukser som du kan skrive ut og henge opp på fartøyet der hvor arbeidsoppgaven utføres.</p>
        <Textarea placeholder="Instruks" name="instruks"></Textarea>
        <SymbolPicker title="Velg symbol(er)" symbols={symbols.map((symbol, index) => {
            return {...symbol, id: index.toString() }
        })}></SymbolPicker>
      </Fieldset>
      <SdirButton text="Nytt tiltak +"></SdirButton>
    </>
  );
};

const Step4 = () => {
  return (
    <>
      <h3>Sikkerhetsinstrukser (valgfritt)</h3>
      <h4>Lag en sikkerhetsinstruks</h4>
      <p>
        Generer en sikkerhetsinstruks som kan skrives ut og henges opp på fartøyet der hvor
        arbeidsoppgaven utføres.
      </p>
      <Fieldset>
        <legend>Instruks 1</legend>
      </Fieldset>
      <SdirButton text="Ny instruks +"></SdirButton>
    </>
  );
};
const Step5 = () => {
  return (
    <>
      <h3>Evaluer faren</h3>
      <p>
        Vurder hvor stor sannsynlighet det er for at faren oppstår — og hvor stor konsekvens det vil
        ha hvis tiltakene iverksettes.
      </p>
      <h4>Hvor sannsynlig er det at dette oppstår?</h4>
      <SdirRadioButtonGroup
        options={[
          {
            label: "Svært usannsynlig — inntreffer én gang pr. 50 år eller sjeldnere",
            value: "1",
          },
          { label: "Usannsynlig — inntreffer én gang pr. 10 år eller sjeldnere", value: "2" },
          { label: "Mulig — inntreffer årlig eller sjeldnere", value: "3" },
          { label: "Sannsynlig — inntreffer flere ganger årlig", value: "4" },
        ]}
        value={"3"}
        onChange={() => console.log("value changed")}
      />
      <br></br>
      <h4>Hva blir konsekvensen dersom dette oppstår?</h4>
      <SdirRadioButtonGroup
        options={[
          { label: "Ufarlig — ubetydelig skade på menneske, miljø eller materiell", value: "1" },
          {
            label:
              "Farlig — Småskader med kort fravær, moderate miljøskader, mindre lokal skade på fartøy/meteriell",
            value: "2",
          },
          {
            label:
              "Kritisk — Alvorlig personskade med langt fravær eller varige mén, større lokale skader på miljø, alvorlige skader på fartøy/materiell",
            value: "3",
          },
          {
            label:
              "Katastrofal — Kan resultere i død for én eller flere personer, meget alvorlige skader på miljø, tap av, eller kritisk skade på fartøy/materiell",
            value: "4",
          },
        ]}
        value={"2"}
        onChange={() => console.log("value changed")}
      />
    </>
  );
};

const Step6 = () => {
  return <h3>Oppsummering</h3>;
};

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem 2rem;
  border: 1px solid ${styles.colors.primary10};
  margin-bottom: 1.5rem;
`;

export function Default(args: any) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <button onClick={() => setIsOpen(true)}>Åpne modal</button>
      <SdirWizard
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        nextText="Neste >"
        prevText="< Forrige"
        cancelText="Avbryt"
        title="Legg til fare"
        okText="Fullfør og legg til"
        onOK={() => console.log("Added")}
        preferredWidth="1200px"
        preferredHeight="600px"
        closeButton={false}
        currentStep={0}
        setCurrentStep={() => {}}
      >
          <DescribeRiskStep title={"Registrer fare"} />
          <AssessBeforeMeasuresStep title={"Vurder fare før tiltak"} />
          <AddMeasuresStep title={"Registrer tiltak"}/>
          <AssessAfterMeasuresStep title={"Evaluer fare etter tiltak"}/>
          <SummaryStep title={"Sammendrag"}  />
      </SdirWizard>
    </>
  );
}
