import { createContext, useContext } from 'react';

export interface FormContextProps {
    getValue: (key: string) => any;
    setValue: (key: string, value: any) => void;
    children?: React.ReactNode
}

const FormContext = createContext<FormContextProps>({
    getValue: (key: string) => "",
    setValue: (key: string, value: any) => null
});

export function FormContextProvider(props: FormContextProps) {
    const {children} = props

    return (
        <FormContext.Provider value={props}>
            {children}
        </FormContext.Provider>
    )
}

export function useFormContext() {
    return useContext(FormContext);
}